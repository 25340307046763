import { FunctionComponent, memo, useMemo } from "react";
import { useQuery } from "react-query";
import { PageContent, PageHeader, Page } from "@edgetier/client-components";
import { IAnomalyCount, Route } from "@edgetier/watchtower-types";
import { SpinnerUntil } from "@edgetier/components";

import { useTimeZone } from "components-for/time-zones/use-time-zone";

import AnomaliesList from "./anomalies-list";
import AnomalyDateFilter from "./anomaly-date-filter";
import { getInitialDateRange, getFilterData } from "./anomalies.utilities";
import { IProps } from "./anomalies.types";
import "./anomalies.scss";

/**
 * List of anomalies triggered.
 * @returns List of anomalies and a da date filter.
 */
const Anomalies: FunctionComponent<IProps> = () => {
    const initialDateRange = useMemo(getInitialDateRange, []);
    const { data: timeZone, isLoading: isTimeZoneLoading } = useTimeZone();

    // When the time zone has been requested count anomalies on each day.
    const { data: anomalyCounts, isLoading: isAnomalyCountsLoading } = useQuery(
        [Route.Anomalies, initialDateRange, timeZone],
        () => getFilterData(Route.Anomalies, initialDateRange, timeZone!),
        {
            enabled: typeof timeZone === "string",
            staleTime: Infinity,
        }
    );

    return (
        <Page className="anomalies">
            <PageHeader title="Anomalies" />
            <PageContent>
                <SpinnerUntil<IAnomalyCount[]>
                    data={anomalyCounts}
                    isReady={!isAnomalyCountsLoading && !isTimeZoneLoading}
                >
                    {(data) => (
                        <>
                            <AnomalyDateFilter anomalyCounts={data} initialDateRange={initialDateRange} />
                            <AnomaliesList initialDateRange={initialDateRange} />
                        </>
                    )}
                </SpinnerUntil>
            </PageContent>
        </Page>
    );
};

export default memo(Anomalies);
