import { FunctionComponent, memo, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { trackUser } from "utilities/track-user";

import { createPageLookup, getPageName } from "./track-page-changes.utilities";
import { IProps } from "./track-page-changes.types";
import "./track-page-changes.scss";

const TrackPageChanges: FunctionComponent<IProps> = () => {
    const location = useLocation();

    const pageLookup = useMemo(createPageLookup, []);

    useEffect(() => {
        const pageName = getPageName(pageLookup, location.pathname);
        if (typeof pageName !== "undefined") {
            trackUser("Page Load", { pageName }, true);
        }
    }, [location.pathname, pageLookup]);

    return null;
};

export default memo(TrackPageChanges);
