import Qs from "qs";
import { getRedirectUri } from "utilities/get-redirect-uri";

const CODE_CHALLENGE_METHOD = "S256";
const RESPONSE_TYPE = "code";
const SCOPE = "aws.cognito.signin.user.admin+email+openid";

/**
 * Generate a secure random string.
 * @returns Random string.
 */

export const generateRandomString = () => {
    const array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, (value) => ("0" + value.toString(16)).substr(-2)).join("");
};

/**
 * Calculate the SHA256 hash of the input text.
 * @param plain Input text.
 * @returns     Promise of an array buffer.
 */
export const hash = (plain: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
};

/**
 * Base64-urlencodes a value.
 * @param value Value to encode.
 * @returns     Encoded value.
 */
const encode = (value: ArrayBuffer) => {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(value) as any))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
};

/**
 * Hash and encode the authentication code verifier for the PKCE challenge.
 * @param codeVerifier Random string to use for the PKCE challenge.
 * @returns            Hashed and encoded value.
 */
const encodeCodeVerifier = async (codeVerifier: string) => {
    const hashed = await hash(codeVerifier);
    return encode(hashed);
};

/**
 * Create a URL for the user to sign in.
 * @returns Sign in URL, state, and verifier.
 */
export const createSignInUrl = async () => {
    // Create and store a random "state" value.
    const state = generateRandomString();

    // Create a PKCE code verifier.
    const codeVerifier = generateRandomString();

    // Hash and base64-urlencode the secret to use as the challenge.
    const codeChallenge = await encodeCodeVerifier(codeVerifier);

    // Generate Cognito login URL.
    const parameters = Qs.stringify(
        {
            response_type: RESPONSE_TYPE,
            client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
            scope: SCOPE,
            state,
            redirect_uri: getRedirectUri(),
            code_challenge: codeChallenge,
            code_challenge_method: CODE_CHALLENGE_METHOD,
        },
        { encode: false }
    );

    const url = `${process.env.REACT_APP_LOGIN_URL}?${parameters}`;

    return { codeVerifier, state, url };
};
