import { FunctionComponent, memo, useCallback, useMemo } from "react";
import { Button } from "@edgetier/components";
import { IInteractionsGetResponse, Route } from "@edgetier/watchtower-types";
import { SpinnerUntil } from "@edgetier/components";
import { useInfiniteQuery } from "react-query";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";

import { axios } from "utilities/axios";
import { trackUser } from "utilities/track-user";

import AnomalyInteractionsTable from "./anomaly-interactions-table";
import { ROW_LIMIT } from "./anomaly-interactions.constants";
import { IProps } from "./anomaly-interactions.types";
import "./anomaly-interactions.scss";

/**
 * Show interactions matching search terms in a time range.
 * @param props.endDateTime     Closing date in search range.
 * @param props.languageIsoCode Language to search.
 * @param props.phrases         Search terms.
 * @param props.startDateTime   Opening date in search range.
 * @returns                     Table of interactions.
 */
const AnomalyInteractions: FunctionComponent<IProps> = ({
    endDateTime,
    languageIsoCode,
    phrases,
    tags,
    startDateTime,
}) => {
    const parameters = {
        endDateTime,
        highlightMaximumWords: 60,
        highlightMinimumWords: 50,
        languageIsoCode,
        limit: ROW_LIMIT,
        tag: tags,
        search: phrases.join(" or "),
        startDateTime,
        webSearchSyntax: true,
    };

    // Request interactions matching the required phrases.
    const { hasNextPage, data, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery(
        [Route.Interactions, parameters],
        async ({ pageParam }) => {
            const request =
                typeof pageParam === "string"
                    ? axios.get<IInteractionsGetResponse>(pageParam)
                    : axios.get<IInteractionsGetResponse>(Route.Interactions, { params: parameters });
            const response = await request;
            return response.data;
        },
        { getNextPageParam: ({ pagination }) => pagination?.nextPage }
    );

    // Combine pages of interactions into one array.
    const interactions = useMemo(() => (data?.pages ?? []).flatMap((interactions) => interactions.items), [data]);

    /**
     * Load more interactions if there are some available.
     */
    const requestMoreInteractions = useCallback(() => {
        fetchNextPage();
        trackUser("Load More Interactions");
    }, [fetchNextPage]);

    return (
        <div className="anomaly-interactions">
            <h3>Example Interactions</h3>
            <SpinnerUntil data={interactions} isReady={!isLoading}>
                {!Array.isArray(interactions) ? (
                    "Error"
                ) : interactions.length === 0 ? (
                    <p>No interactions found.</p>
                ) : (
                    <>
                        <AnomalyInteractionsTable hasNextPage={hasNextPage} interactions={interactions} />

                        {hasNextPage && (
                            <Button
                                icon={faEllipsisV}
                                isLoading={isFetchingNextPage !== false}
                                onClick={requestMoreInteractions}
                            >
                                Load More
                            </Button>
                        )}
                    </>
                )}
            </SpinnerUntil>
        </div>
    );
};

export default memo(AnomalyInteractions);
