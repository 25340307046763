import { FunctionComponent, memo, useMemo } from "react";
import { differenceInCalendarDays, format } from "date-fns";

import { getMonths } from "./anomaly-date-filter-months.utilities";
import { IProps } from "./anomaly-date-filter-months.types";
import "./anomaly-date-filter-months.scss";

/**
 * Show months in the date filter's range.
 * @param props.firstDateTime Opening date in the filter range.
 * @param props.lastDateTime  Closing date in the filter range.
 * @returns                   Month names.
 */
const AnomalyDateFilterMonths: FunctionComponent<IProps> = ({ dayWidth, firstDateTime, lastDateTime }) => {
    const months = useMemo(() => getMonths(firstDateTime, lastDateTime), [firstDateTime, lastDateTime]);

    return (
        <div
            className="anomaly-date-filter-months"
            style={{ width: `${dayWidth * (differenceInCalendarDays(lastDateTime, firstDateTime) + 1)}px` }}
        >
            {months.map(({ dateTime, dayCount }) => (
                <div
                    className="anomaly-date-filter-months__month"
                    key={dateTime.toISOString()}
                    style={{ flexBasis: `${dayCount * dayWidth}px` }}
                >
                    {format(dateTime, "MMM")}
                </div>
            ))}
        </div>
    );
};

export default memo(AnomalyDateFilterMonths);
