import Qs from "qs";
import { Button } from "@edgetier/components";
import { FunctionComponent, memo, useCallback, useMemo } from "react";
import { SpinnerUntil } from "@edgetier/components";
import { useInfiniteQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { Route } from "@edgetier/watchtower-types";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { trackUser } from "utilities/track-user";

import AnomalySummary from "./anomaly-summary";
import { requestAnomalies } from "./anomalies-list.utilities";
import { IProps } from "./anomalies-list.types";
import "./anomalies-list.scss";

/**
 * List all anomalies that occurred in a date range specified by the user.
 * @param props.initialDateRange Date range when the page loads. If the users changes the filter that will come from
 *                               the URL.
 * @returns                      List of anomalies.
 */
const AnomaliesList: FunctionComponent<IProps> = ({ initialDateRange }) => {
    const location = useLocation();
    const searchParameters = Qs.parse(location.search, { ignoreQueryPrefix: true });

    // Request anomalies in the date range. There is a "load more" button, so this is an infinite query.
    const { hasNextPage, data, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery(
        [Route.Anomalies, searchParameters, initialDateRange],
        ({ pageParam }) => requestAnomalies(Route.Anomalies, searchParameters, initialDateRange, pageParam),
        { getNextPageParam: ({ pagination }) => pagination.nextPage }
    );

    /**
     * Load more anomalies if there are some available.
     */
    const requestMoreAnomalies = useCallback(() => {
        fetchNextPage();
        trackUser("Load More Anomalies");
    }, [fetchNextPage]);

    // Combine pages of anomalies into one array.
    const anomalies = useMemo(() => (data?.pages ?? []).flatMap((anomalies) => anomalies.items), [data]);

    return (
        <div className="anomalies-list">
            <SpinnerUntil data={anomalies} isReady={!isLoading}>
                {anomalies.length === 0 && <p>No anomalies found</p>}

                {anomalies.map((anomaly) => (
                    <AnomalySummary anomaly={anomaly} key={anomaly.anomalyId} />
                ))}

                {hasNextPage && (
                    <Button icon={faEllipsisV} isLoading={isFetchingNextPage !== false} onClick={requestMoreAnomalies}>
                        Load More
                    </Button>
                )}
            </SpinnerUntil>
        </div>
    );
};

export default memo(AnomaliesList);
