import { parseISO } from "date-fns";
import { ParsedQs } from "qs";

/**
 * Parse a string date coming from a URL.
 * @param dateTime        Date string from a URL.
 * @param defaultDateTime Default value if the date is not provided.
 * @returns               Date or default value.
 */
export const parseUrlDateTime = <T extends {} | null>(
    dateTime: string | ParsedQs | string[] | ParsedQs[] | undefined,
    defaultDateTime: T
) => {
    return typeof dateTime === "string" ? parseISO(dateTime) : defaultDateTime;
};
