import { FunctionComponent, memo } from "react";
import { Page, PageContent, PageHeader } from "@edgetier/client-components";

import ExploreFilters from "./explore-filters";
import ExploreResults from "./explore-results";
import { IProps } from "./explore.types";
import "./explore.scss";

const Explore: FunctionComponent<IProps> = () => {
    return (
        <Page className="explore">
            <PageHeader title="Explore" />
            <PageContent>
                <ExploreFilters />
                <ExploreResults />
            </PageContent>
        </Page>
    );
};

export default memo(Explore);
