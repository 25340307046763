import classNames from "classnames";
import React, { Children, FunctionComponent, isValidElement, memo, ReactElement } from "react";
import { components } from "react-select";

import { IProps } from "./select-menu-list.types";

/**
 * Separate selected and not selected options in a select menu list.
 * @param props.children      Select options.
 * @param props.menuListProps Other menu props.
 * @returns                   Select menu list with selected options at the top.
 */
const SelectMenuList: FunctionComponent<IProps> = ({ children, ...menuListProps }) => {
    const childrenArray = Children.toArray(children);
    const validChildren = childrenArray.filter((child): child is ReactElement => isValidElement(child));

    // All selected options.
    const selected = validChildren.filter((child) => child.props.isSelected);

    // All options that have not been selected, or just any other children.
    const notSelected = validChildren.filter((child) => child.props?.isSelected !== true);

    return (
        <components.MenuList {...menuListProps}>
            <div
                className={classNames("select-menu-list", {
                    "select-menu-list--has-unselected": notSelected.length > 0,
                })}
            >
                {selected.length > 0 && <div className={"select-menu-list__selected"}>{selected}</div>}
                {notSelected}
            </div>
        </components.MenuList>
    );
};

export default memo(SelectMenuList);
