import classNames from "classnames";
import React, { FunctionComponent } from "react";

import { IProps } from "./box.types";

/**
 * A basic container with some styling.
 * @param props.children     Box content.
 * @param props.className    Optional extra class name(s) to add.
 * @param props.innerPadding Whether to apply padding to the box or first element inside.
 * @returns                  Content in a container.
 */
const Box: FunctionComponent<IProps> = ({ children, className, innerPadding = false }) => {
    return (
        <div
            className={classNames("box", className, {
                "box--inner-padding": innerPadding,
                "box--outer-padding": !innerPadding,
            })}
        >
            {children}
        </div>
    );
};

export default Box;
