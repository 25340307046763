import { FunctionComponent, memo, useMemo } from "react";
import { IAnomaly, Route } from "@edgetier/watchtower-types";
import { Page, PageHeader, PageContent, ampersandList, FormattedDateTime } from "@edgetier/client-components";
import { SpinnerUntil } from "@edgetier/components";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import AnomalyInteractions from "components-for/interactions/anomaly-interactions";
import { axios } from "utilities/axios";
import { getAnomalyPhrases } from "utilities/get-anomaly-phrases";
import { useAnomalyEvents } from "components-for/anomalies/use-anomaly-events";
import { useModel } from "components-for/anomalies/use-model";

import AnomalySummaryValue from "./anomaly-summary-value";
import AnomalyPhraseCountsChart from "./anomaly-phrase-counts-chart";
import ExploreLink from "./explore-link";
import { IProps } from "./anomaly.types";
import "./anomaly.scss";

const Anomaly: FunctionComponent<IProps> = () => {
    const { anomalyId } = useParams<{ readonly anomalyId: string }>();

    // Request anomaly.
    const { data: anomaly, isLoading: anomalyIsLoading } = useQuery([Route.Anomaly, anomalyId], async () => {
        const response = await axios.get<IAnomaly>(Route.Anomaly.replace(":anomalyId", anomalyId));
        return response.data;
    });

    // Events belonging to the anomaly.
    const { data: anomalyEvents, isLoading: anomalyEventsAreLoading } = useAnomalyEvents(anomalyId);

    // Model that detected this anomaly.
    const { data: model, isLoading: modelIsLoading } = useModel(anomaly?.modelId!, {
        enabled: typeof anomaly !== "undefined",
    });

    const phrases = useMemo(() => getAnomalyPhrases(anomalyEvents || []), [anomalyEvents]);

    return (
        <Page className="anomaly">
            <SpinnerUntil
                data={[anomaly, anomalyEvents, !model]}
                isReady={!anomalyIsLoading && !anomalyEventsAreLoading && !modelIsLoading}
            >
                {!anomaly || !anomalyEvents || !model ? (
                    "Error"
                ) : (
                    <>
                        <PageHeader title={model ? `${phrases[0]} Anomaly (${model.model})` : `${phrases[0]} Anomaly`}>
                            <ExploreLink
                                anomalyId={anomalyId}
                                endDateTime={anomaly.endDateTime}
                                languageIsoCode={
                                    "languageIsoCode" in model.configuration
                                        ? model.configuration.languageIsoCode
                                        : "en"
                                }
                                phrases={phrases}
                                startDateTime={anomaly.startDateTime}
                                tags={model.tags}
                            />
                        </PageHeader>

                        <PageContent>
                            <p>
                                <strong>Common phrase(s): </strong> {ampersandList(phrases, { quote: true })}
                            </p>

                            <div className="anomaly__summary">
                                <div className="anomaly__metrics">
                                    <AnomalySummaryValue title="Anomaly First Detected">
                                        <FormattedDateTime dateTime={anomaly.startDateTime} />
                                    </AnomalySummaryValue>

                                    <AnomalySummaryValue title="Anomaly End">
                                        {anomaly.endDateTime instanceof Date ? (
                                            <FormattedDateTime dateTime={anomaly.endDateTime} />
                                        ) : (
                                            <>Ongoing</>
                                        )}
                                    </AnomalySummaryValue>
                                </div>

                                <AnomalyPhraseCountsChart
                                    anomalyEndDateTime={anomaly.endDateTime}
                                    anomalyStartDateTime={anomaly.startDateTime}
                                    dateTime={anomaly.startDateTime}
                                    languageIsoCode={
                                        "languageIsoCode" in model.configuration
                                            ? model.configuration.languageIsoCode
                                            : "en"
                                    }
                                    phrases={phrases}
                                    tags={model.tags}
                                />
                            </div>

                            {/* TODO: Careful with the "new Date()" here. */}
                            <AnomalyInteractions
                                endDateTime={anomaly.endDateTime ?? new Date()}
                                languageIsoCode={
                                    "languageIsoCode" in model.configuration
                                        ? model.configuration.languageIsoCode
                                        : "en"
                                }
                                phrases={phrases}
                                startDateTime={anomaly.startDateTime}
                                tags={model.tags}
                            />
                        </PageContent>
                    </>
                )}
            </SpinnerUntil>
        </Page>
    );
};

export default memo(Anomaly);
