import React, { FunctionComponent, memo } from "react";

import { IProps } from "./client-navigation.types";

const ClientNavigation: FunctionComponent<IProps> = ({ children, logo }) => {
    return (
        <nav className="client-navigation">
            <div className="client-navigation__logo">
                <img src={logo} alt="EdgeTier" />
            </div>

            <ul className="client-navigation__links">{children}</ul>
        </nav>
    );
};

export default memo(ClientNavigation);
