import React from "react";
import { Field, FieldProps } from "formik";
import { createFieldErrorId } from "@edgetier/utilities";

import UniqueId from "../unique-id";

import CheckedIcon from "./checked-icon";
import UncheckedIcon from "./unchecked-icon";
import { IProps } from "./radio.types";

/**
 * Render a radio button with custom icons that uses Formik.
 * @param props.autoFocus      Automatically focus on the field when it renders.
 * @param props.label          Input label.
 * @param props.name           Field name.
 * @param props.option         Value for the radio button if selected.
 * @param props.transformValue Optional function to transform the value before updating the form.
 * @returns                    Radio input.
 */
const Radio = <T extends {}, V extends {} = T>({
    autoFocus,
    label,
    name,
    option,
    transformValue = (value: any) => value,
}: IProps<T, V>) => (
    <UniqueId>
        {(uniqueId) => (
            <Field name={name}>
                {({ field, form }: FieldProps) => (
                    <div className="radio">
                        <input
                            aria-describedby={createFieldErrorId(name)}
                            aria-invalid={typeof form.errors[name] === "string"}
                            autoFocus={autoFocus}
                            checked={option === field.value}
                            id={uniqueId}
                            name={name}
                            onChange={(changeEvent) =>
                                changeEvent.target.checked && form.setFieldValue(field.name, transformValue(option))
                            }
                            type="radio"
                        />
                        <label htmlFor={uniqueId}>
                            {(Array.isArray(field.value) && field.value.includes(option)) || field.value === option ? (
                                <CheckedIcon />
                            ) : (
                                <UncheckedIcon />
                            )}
                            <span className="radio__label-text">{label}</span>
                        </label>
                    </div>
                )}
            </Field>
        )}
    </UniqueId>
);

export default Radio;
