import React, { FunctionComponent, memo } from "react";
import { format } from "date-fns";

import FormattedDate from "../formatted-date";

import { IProps } from "./formatted-date-time.types";

/**
 * Format a date for a user including both the date and time.
 * @param props.dateTime Date to render.
 * @returns              Formatted date.
 */
const FormattedDateTime: FunctionComponent<IProps> = ({ dateTime }) => {
    return (
        <div className="formatted-date-time">
            <FormattedDate dateTime={dateTime} />{" "}
            <span className="formatted-date-time__time">{format(dateTime, "p")}</span>
        </div>
    );
};

export default memo(FormattedDateTime);
