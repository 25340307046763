import { FunctionComponent, memo } from "react";

import { Route, Switch } from "react-router-dom";

import Authenticate from "pages/authenticate";
import Page from "constants/page";
import Login from "pages/login";

import AuthenticatedRoutes from "./authenticated-routes";
import { IProps } from "./routes.types";
import "./routes.scss";

const Routes: FunctionComponent<IProps> = () => {
    return (
        <Switch>
            <Route exact={true} path={Page.Login}>
                <Login />
            </Route>

            <Route exact={true} path={Page.Authenticate}>
                <Authenticate />
            </Route>

            <Route path={Page.Main}>
                <AuthenticatedRoutes />
            </Route>
        </Switch>
    );
};

export default memo(Routes);
