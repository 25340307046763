import React, { FunctionComponent } from "react";

import { IProps } from "./page-header.types";

/**
 * Header with title above a page's content.
 * @param title Name of the page.
 * @returns     Page header.
 */
const PageHeader: FunctionComponent<IProps> = ({ children, title }) => {
    return (
        <div className="page-header">
            <h2>{title}</h2>
            {children}
        </div>
    );
};

export default PageHeader;
