import React, { FunctionComponent, memo } from "react";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IProps } from "./client-sign-out.types";

/**
 * Sign out button.
 * @returns Button.
 */
const ClientSignout: FunctionComponent<IProps> = ({ onClick }) => {
    return (
        <li className="client-sign-out navigation-link" onClick={onClick}>
            <div className="navigation-link__inner">
                <FontAwesomeIcon icon={faSignOutAlt} />
                Sign Out
            </div>
        </li>
    );
};

export default memo(ClientSignout);
