import Qs from "qs";
import React, { FunctionComponent, memo } from "react";
import { faSearchPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Page from "constants/page";

import { IProps } from "./explore-link.types";
import "./explore-link.scss";
import { Link } from "react-router-dom";
import { trackUser } from "utilities/track-user";

/**
 * Link to the explore page with phrases and dates pre-populated.
 * @param props.anomalyId       The anomaly being explored.
 * @param props.languageIsoCode Language of the anomaly.
 * @param props.startDateTime   Start of anomaly.
 * @param props.endDateTime     End of anomaly (may not be defined yet).
 * @param props.phrases         Array of phrases in the anomaly.
 * @param props.tags            Tags associated with the anomaly's model.
 * @returns                     Link button to the explore screen.
 */
const ExploreLink: FunctionComponent<IProps> = ({
    anomalyId,
    languageIsoCode,
    startDateTime,
    endDateTime,
    phrases,
    tags,
}) => {
    return (
        <Link
            className="button"
            onClick={() => trackUser("Explore Anomaly", { anomalyId }, true)}
            to={{
                pathname: Page.Explore,
                search: Qs.stringify(
                    { startDateTime, endDateTime, languageIsoCode, phrase: phrases.join(" or "), tag: tags },
                    { addQueryPrefix: true, arrayFormat: "repeat", skipNulls: true }
                ),
            }}
        >
            <FontAwesomeIcon icon={faSearchPlus} />
            Explore
        </Link>
    );
};

export default memo(ExploreLink);
