import urlJoin from "url-join";
import {
    ChatEventType,
    MessageRecipientType,
    MessageType,
    Url,
    IMessage,
    IOutboundAttachment,
    ITranslations,
} from "@edgetier/types";

/**
 * Trim a message's text. Or, if it's an empty string, return null.
 * @param translations Original message text.
 * @returns       Trimmed original message or null if the message is actually blank.
 *
 * @example
 * // Returns null.
 * cleanMessage('  ');
 *
 * // Returns "Message".
 * cleanMessage('Message  ');
 */
function cleanMessage(message: string | null): string | null {
    return typeof message !== "string" || message.trim() === "" ? null : message.trim();
}

/**
 * Trim the translation text.
 * @param translations Translations before cleaning;
 * @returns Translations with a trimmed message or null if the message is actually blank.
 */
function cleanTranslations(translations: ITranslations) {
    const languageIds = Object.keys(translations);
    return languageIds.reduce((cleanedTranslations, languageId) => {
        const translation = translations[parseInt(languageId)];
        return {
            ...cleanedTranslations,
            [languageId]: {
                ...translation,
                translation: cleanMessage(translation.translation),
            },
        };
    }, translations);
}

/**
 * Format a chat message to save in the store.
 * @param chatToken              Chat token for the current chat.
 * @param translations           Text content of the message in multiple languages.
 * @param messageTranslation     Translation of the message text.
 * @param messageRecipientTypeId Who will receive the message.
 * @param messageTypeId          Type of message.
 * @param attachments            List of attachments to include.
 * @returns                      Chat message.
 */
export function createChatMessage(
    chatToken: string,
    message: string | null,
    translations: ITranslations,
    messageRecipientTypeId: MessageRecipientType,
    messageTypeId: MessageType,
    attachments: IOutboundAttachment[]
): IMessage {
    return {
        attachments: attachments.map(({ attachment, attachmentId }) => ({
            attachment,
            attachmentId: attachmentId!,
            url: attachmentId === null ? "" : urlJoin(Url.Attachments, attachmentId.toString()),
            deleted: false,
        })),
        chatEventType: "MESSAGE",
        chatEventTypeId: ChatEventType.Message,
        chatToken,
        error: null,
        form: null,
        timestamp: new Date().toISOString(),
        translations: cleanTranslations(translations),
        message: cleanMessage(message),
        messageRecipientTypeId,
        messageTypeId,
        sent: false,
    };
}
