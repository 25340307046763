import { Fragment, FunctionComponent } from "react";
import { BrowserRouter } from "react-router-dom";
import { ClientApplication } from "@edgetier/client-components";
import { QueryClientProvider, QueryClient } from "react-query";
import { Toaster } from "react-hot-toast";

import Routes from "components-for/application/routes";

import TrackPageChanges from "./track-page-changes";
import { IProps } from "./application.types";
import "./application.scss";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});

const Application: FunctionComponent<IProps> = () => {
    return (
        <Fragment>
            <Toaster position="bottom-right" toastOptions={{ style: { backgroundColor: "#34495e", color: "white" } }} />

            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <TrackPageChanges />

                    <ClientApplication>
                        <Routes />
                    </ClientApplication>
                </BrowserRouter>
            </QueryClientProvider>
        </Fragment>
    );
};

export default Application;
