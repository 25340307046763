import { FunctionComponent, memo } from "react";
import { Form, Formik } from "formik";
import { SubmitOnChange } from "@edgetier/components";
import { SelectEnum } from "@edgetier/client-components";
import { TimePeriodGroupBy } from "@edgetier/watchtower-types";

import AnomalyPhraseCountsSelectRange from "./anomaly-phrase-counts-select-range";
import ProportionToggle from "./proportion-toggle";
import { IProps } from "./anomaly-phrase-counts-chart-filters.types";
import "./anomaly-phrase-counts-chart-filters.scss";

/**
 * Choose some display options on the phrase counts chart.
 * @returns Form containing options for range, grouping, and axis.
 */
const AnomalyPhraseCountsChartFilters: FunctionComponent<IProps> = ({
    filterRange = true,
    initialValues,
    onChange,
    rangeOptions,
}) => {
    return (
        <Formik initialValues={initialValues} onSubmit={onChange}>
            <Form className="anomaly-phrase-counts-chart-filters">
                {filterRange && <AnomalyPhraseCountsSelectRange options={rangeOptions} />}
                <SelectEnum isClearable={false} name="groupBy" options={TimePeriodGroupBy} placeholder="Group by..." />
                <ProportionToggle name="proportion" />
                <SubmitOnChange />
            </Form>
        </Formik>
    );
};

export default memo(AnomalyPhraseCountsChartFilters);
