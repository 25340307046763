import { subMonths, startOfMonth, endOfMonth } from "date-fns";
import { axios } from "utilities/axios";
import {
    AnomalyEventType,
    IAnomaliesCountGetResponse,
    IAnomaliesGetQueryParameters,
    ModelType,
    TimePeriodGroupBy,
} from "@edgetier/watchtower-types";

// By default, how many months are displayed by the filter.
export const FILTER_MONTH_RANGE = 3;

/**
 * Count the number of anomalies in the range.
 * @param timeZone User's time zone.
 * @param url      API URL.
 * @returns        Promise of anomaly counts.
 */
export const getFilterData = async (url: string, [startDateTime, endDateTime]: Date[], timeZone: string) => {
    const requestParameters: IAnomaliesGetQueryParameters = {
        anomalyEventType: AnomalyEventType.New,
        groupBy: TimePeriodGroupBy.Days,
        startDateTime,
        endDateTime,
        modelType: ModelType.Text,
        timeZone,
    };
    const response = await axios.get<IAnomaliesCountGetResponse>(url, { params: requestParameters });
    return response.data.items;
};

/**
 * Get the default filter range.
 * @returns Start and end date time tuple.
 */
export const getInitialDateRange = (): [Date, Date] => {
    const today = new Date();
    const startDateTime = subMonths(startOfMonth(today), FILTER_MONTH_RANGE - 1);
    const endDateTime = endOfMonth(today);
    return [startDateTime, endDateTime];
};
