import { ClientAuthenticatedRoutes } from "@edgetier/client-components";
import { initialiseTracking } from "utilities/track-user";
import { IUser } from "@edgetier/watchtower-types";
import { FunctionComponent, memo, useCallback, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import urlJoin from "url-join";

import Alerts from "pages/alerts";
import Anomaly from "pages/anomaly";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Explore from "pages/explore";
import Page from "constants/page";
import Navigation from "components-for/application/navigation";

import { IProps } from "./authenticated-routes.types";
import { deleteAccessToken, getAccessToken } from "utilities/access-token";
import { axios, createAuthorisationHeader, refreshToken } from "utilities/axios";
import "./authenticated-routes.scss";
import { requestUser } from "components-for/application/routes/authenticated-routes/authenticated-routes.utilities";

// TODO: Authentication failure not working. User isn't signed out.

const AuthenticatedRoutes: FunctionComponent<IProps> = () => {
    const history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    /**
     * If there's an authentication problem, delete any access token and redirect the user to the login screen.
     */
    const onAuthenticationFailure = useCallback(() => {
        deleteAccessToken();
        history.push(Page.Login);
    }, [history]);

    /**
     * Once authenticated, set up an interceptor to handle token refreshes.
     */
    const onAuthenticated = useCallback(
        (user: IUser) => {
            setIsAuthenticated(true);
            initialiseTracking(user.userId, `${user.firstName} ${user.surname}`, user.emailAddress);

            // Generate a new access token when the current one expires.
            createAuthRefreshInterceptor(axios, async (request) => {
                try {
                    await refreshToken();
                    request.response.config.headers["Authorization"] = createAuthorisationHeader();
                    return Promise.resolve();
                } catch {
                    toast.error("Authentication failed.");
                    onAuthenticationFailure();
                }
            });
        },
        [onAuthenticationFailure]
    );

    // On load, check if the user is signed in, and attempt to authenticate if not.
    useEffect(() => {
        const authenticate = async () => {
            const accessToken = getAccessToken();
            if (typeof accessToken == "string") {
                const user = await requestUser();
                onAuthenticated(user);
            } else {
                try {
                    await refreshToken();
                    const user = await requestUser();
                    onAuthenticated(user);
                } catch {
                    onAuthenticationFailure();
                }
            }
        };

        authenticate();
    }, [onAuthenticated, onAuthenticationFailure]);

    return (
        <>
            {isAuthenticated && (
                <ClientAuthenticatedRoutes>
                    <Navigation />

                    <Switch>
                        <Route path={urlJoin(Page.Anomaly, ":anomalyId")}>
                            <Anomaly />
                        </Route>

                        <Route path={Page.Anomalies}>
                            <Alerts />
                        </Route>

                        <Route path={Page.Explore}>
                            <Explore />
                        </Route>
                    </Switch>
                </ClientAuthenticatedRoutes>
            )}
        </>
    );
};

export default memo(AuthenticatedRoutes);
