import Qs from "qs";
import { useLocation } from "react-router-dom";
import { parseUrlDateTime } from "utilities/parse-url-date-time";

export const useExploreParameters = () => {
    const location = useLocation();
    const { endDateTime, languageIsoCode, phrase, startDateTime, tag } = Qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    return {
        endDateTime: parseUrlDateTime(endDateTime, null),
        languageIsoCode: typeof languageIsoCode === "string" ? languageIsoCode : null,
        phrase: typeof phrase === "string" ? phrase : null,
        startDateTime: parseUrlDateTime(startDateTime, null),
        tags: Array.isArray(tag) ? (tag as string[]) : typeof tag === "string" ? [tag] : [],
    };
};
