import React, { useMemo } from "react";
import { startOfDay, endOfDay, isBefore } from "date-fns";
import DayPicker, { RangeModifier } from "react-day-picker";

import { IFixedDateRangeInput } from "./fixed-date-range-input.types";

/**
 * FixedDateRangeInput deals exclusively with days and is produces fixed day output only
 * @param startDate    A Date
 * @param endDate      A Date
 * @param onChange     A callback fired on every change
 */
const FixedDateRangeInput: IFixedDateRangeInput = function FixedDateRangeInput({ startDate, endDate, onChange }) {
    const handleDayClick = (day: Date) => {
        if (typeof startDate !== "undefined" && typeof endDate !== "undefined") {
            onChange({
                startDate: startOfDay(day),
                endDate: undefined,
            });
        } else if (typeof startDate === "undefined") {
            onChange({
                startDate: startOfDay(day),
            });
        } else if (isBefore(day, startDate)) {
            onChange({
                startDate: startOfDay(day),
                endDate: endOfDay(startDate),
            });
        } else {
            onChange({
                endDate: endOfDay(day),
            });
        }
    };

    /**
     * Get the day or range of days selected.
     * @returns An object suitable for the day picker library.
     */
    const selectedDays: Date | RangeModifier | undefined = useMemo(() => {
        if (typeof endDate === "undefined") {
            return startDate;
        } else if (typeof startDate !== "undefined") {
            return { from: startDate, to: endDate };
        }
        return;
    }, [startDate, endDate]);

    return (
        <div className="date-filter__select-date">
            <DayPicker
                firstDayOfWeek={1}
                onDayClick={handleDayClick}
                numberOfMonths={3}
                toMonth={new Date()}
                selectedDays={selectedDays}
            />
        </div>
    );
};

export default FixedDateRangeInput;
