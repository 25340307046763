import React, { FunctionComponent, memo } from "react";

import { FormattedDate } from "@edgetier/client-components";

import { IProps } from "./phrase-counts-chart-tooltip.types";
import "./phrase-counts-chart-tooltip.scss";
import { addHours, format } from "date-fns";

/**
 * Tooltip of how many interactions matched the search phrase(s) at a given date and time.
 * @param props.dateTime Date and time of reading.
 * @param props.showTime Whether to show the time with the date or not.
 * @param props.value    Number of interactions.
 * @returns              Tooltip content.
 */
const PhraseCountsChartTooltip: FunctionComponent<IProps> = ({ dateTime, showTime, value }) => {
    return (
        <div className="phrase-counts-chart-tooltip">
            <FormattedDate dateTime={dateTime} />
            {showTime && (
                <div className="phrase-counts-chart-tooltip__time">
                    {format(dateTime, "p")} &ndash; {format(addHours(dateTime, 1), "p")}
                </div>
            )}
            <div className="phrase-counts-chart-tooltip__value">{value.toLocaleString()}</div>
            interactions
        </div>
    );
};

export default memo(PhraseCountsChartTooltip);
