import Page from "constants/page";

type IPageLookup = { [index: string]: string };

export const createPageLookup = () => {
    return Object.entries(Page).reduce<IPageLookup>((data, [page, url]) => ({ ...data, [url]: page }), {});
};

export const getPageName = (pageLookup: IPageLookup, path: string) => {
    const match = path.match(/(\/main\/[a-z-]+)/);
    if (match && match.length > 0) {
        return pageLookup[match[0]];
    }
};
