import React, { FunctionComponent, memo } from "react";
import { format } from "date-fns";

import { IProps } from "./anomaly-date-filter-preview.types";
import "./anomaly-date-filter-preview.scss";

const AnomalyDateFilterPreview: FunctionComponent<IProps> = ({ startDateTime, endDateTime }) => {
    return (
        <div className="anomaly-date-filter-preview">
            {format(startDateTime, "dd MMM yyyy")} &mdash; {format(endDateTime, "d MMM yyyy")}
        </div>
    );
};

export default memo(AnomalyDateFilterPreview);
