import classNames from "classnames";
import format from "date-fns/format";
import React from "react";

import { EMPTY_DATE_DISPLAY } from "./date-display.constants";
import { IProps } from "./date-display.types";

/**
 * Display a date selected in the date filter.
 * @param props.date Selected date which could be undefined.
 * @returns          Formatted date.
 */
const DateDisplay = ({ date }: IProps) => (
    <div
        className={classNames("date-filter__date-display", {
            "date-filter__date-display--empty": typeof date === "undefined",
        })}
    >
        {typeof date === "undefined" ? EMPTY_DATE_DISPLAY : format(date, "d/M/yy")}
    </div>
);

export default DateDisplay;
