import Qs from "qs";
import { FunctionComponent, memo, useMemo, useEffect, useState, useRef } from "react";
import { Rnd } from "react-rnd";
import { addDays, compareAsc, endOfDay, startOfDay, subDays } from "date-fns";
import { useThrottleCallback } from "@react-hook/throttle";
import { useHistory, useLocation } from "react-router";
import { useMeasure } from "react-use";

import { parseUrlDateTime } from "utilities/parse-url-date-time";

import AnomalyDateFilterMonths from "./anomaly-date-filter-months";
import AnomalyDateFilterPreview from "./anomaly-date-filter-preview";
import AnomalyDateFilterHandle from "./anomaly-date-filter-handle";
import { FILTER_FRAMES_PER_SECOND } from "./anomaly-date-filter.constants";
import { getDimensions } from "./anomaly-date-filter.utilities";
import { IProps } from "./anomaly-date-filter.types";
import "./anomaly-date-filter.scss";

/**
 * Draggable date filter to choose a range to see anomalies. It also shows a bar chart of the anomalies that occurred in
 * the available date range.
 * @param props.anomalyCounts    Counts of daily anomalies.
 * @param props.initialDateRange Default date range when the page loads.
 * @returns                      Date filter.
 */
const AnomalyDateFilter: FunctionComponent<IProps> = ({ anomalyCounts, initialDateRange }) => {
    const isFirstRender = useRef(true);
    const history = useHistory();
    const location = useLocation();
    const [ref, { width }] = useMeasure<HTMLDivElement>();

    // Find the largest count (for vertical scaling) and sort the data by date.
    const { maximum, ordered } = useMemo(
        () => ({
            maximum: Math.max(...anomalyCounts.map(({ count }) => count)),
            ordered: anomalyCounts.slice().sort((one, two) => compareAsc(one.dateTime, two.dateTime)),
        }),
        [anomalyCounts]
    );

    // Select the full range by default.
    const parameters = Qs.parse(location.search, { ignoreQueryPrefix: true });
    const [dateRange, setDateRange] = useState<[Date, Date]>([
        parseUrlDateTime(parameters.startDateTime, initialDateRange[0]),
        parseUrlDateTime(parameters.endDateTime, initialDateRange[1]),
    ]);

    // When the URL changes update the display.
    useEffect(() => {
        // Don't both running this on the first render.
        if (!isFirstRender.current) {
            setDateRange([
                parseUrlDateTime(parameters.startDateTime, initialDateRange[0]),
                parseUrlDateTime(parameters.endDateTime, initialDateRange[1]),
            ]);
        }
    }, [initialDateRange, parameters.startDateTime, parameters.endDateTime]);

    // Track the first render.
    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    // Calculate the slider's position.
    const { dayWidth, sliderLeft, sliderWidth } = useMemo(() => getDimensions(width, initialDateRange, dateRange), [
        width,
        dateRange,
        initialDateRange,
    ]);

    /**
     * Handle the user changing the date filter.
     * @param sliderWidth  New width as the user is resizing.
     * @param direction    Which handle the user is dragging.
     * @param updateParent Whether to update the URL or not. It will update when the handle is released.
     */
    const onChange = useThrottleCallback((sliderWidth: number, direction: string, updateUrl: boolean) => {
        const dayRange = sliderWidth / dayWidth;
        const startDateTime = direction === "left" ? startOfDay(subDays(dateRange[1], dayRange - 1)) : dateRange[0];
        const endDateTime = direction === "right" ? endOfDay(addDays(dateRange[0], dayRange - 1)) : dateRange[1];

        if (updateUrl) {
            const search = Qs.stringify({ startDateTime, endDateTime }, { addQueryPrefix: true });
            history.push({ search });
        } else {
            setDateRange([startDateTime, endDateTime]);
        }
    }, FILTER_FRAMES_PER_SECOND);

    return (
        <div className="anomaly-date-filter" ref={ref}>
            <AnomalyDateFilterPreview startDateTime={dateRange[0]} endDateTime={dateRange[1]} />
            <div className="anomaly-date-filter__bars" style={{ maxWidth: `${dayWidth * anomalyCounts.length}px` }}>
                <Rnd
                    bounds="parent"
                    className="anomaly-date-filter__range"
                    disableDragging={true}
                    enableResizing={{
                        top: false,
                        right: true,
                        bottom: false,
                        left: true,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                    }}
                    resizeGrid={[dayWidth, 1]}
                    resizeHandleComponent={{
                        left: <AnomalyDateFilterHandle />,
                        right: <AnomalyDateFilterHandle />,
                    }}
                    onResize={(_event, direction, { clientWidth }) => onChange(clientWidth, direction, false)}
                    onResizeStop={(_event, direction, { clientWidth }) => onChange(clientWidth, direction, true)}
                    position={{ x: sliderLeft, y: 0 }}
                    size={{ width: sliderWidth, height: "100%" }}
                />

                {ordered.map(({ count, dateTime }) => (
                    <div
                        className="anomaly-date-filter__bar"
                        key={dateTime.toISOString()}
                        style={{ height: `${100 * (count / maximum)}%`, width: `${dayWidth}px` }}
                    />
                ))}
            </div>
            <AnomalyDateFilterMonths
                dayWidth={dayWidth}
                firstDateTime={initialDateRange[0]}
                lastDateTime={initialDateRange[1]}
            />
        </div>
    );
};

export default memo(AnomalyDateFilter, () => false);
