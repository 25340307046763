let accessToken: string | null = null;

/**
 * Retrieve the in-memory access token.
 * @returns The access token.
 */
export const getAccessToken = () => accessToken;

/**
 * Store the access token.
 * @param newAccessToken Access token.
 */
export const setAccessToken = (newAccessToken: string) => {
    accessToken = newAccessToken;
};

/**
 * Retrieve the in-memory access token.
 * @returns The access token.
 */
export const deleteAccessToken = () => {
    accessToken = null;
};
