/**
 * Create a dictionary mapping IDs to items.
 * @param items Array of items to key.
 * @param getId Getter to retrieve the ID of an item.
 * @returns     Lookup from ID to item.
 */
export function keyById<T>(items: T[], getId: (item: T) => number) {
    const result: { [index: number]: T } = {};
    items.forEach((item) => {
        result[getId(item)] = item;
    });
    return result;
}
