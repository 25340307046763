import { axios } from "utilities/axios";
import { IPaginated } from "@edgetier/types";

/**
 * Get all items for a paginated URL.
 * @param url URL to request.
 */
export const getAllPaginatedItems = async <T extends {}>(url: string) => {
    let response = await axios.get<IPaginated<T>>(url, { params: { limit: 50 } });
    const items: T[] = response.data.items;

    // Keep requesting until there are no more pages.
    while (response.data.pagination.nextPage) {
        response = await axios.get(response.data.pagination.nextPage);
        items.push(...response.data.items);
    }

    return items;
};
