import { FunctionComponent, memo } from "react";

import { IProps } from "./anomaly-title.types";
import "./anomaly-title.scss";
import { getAnomalyPhrases } from "utilities/get-anomaly-phrases";

const AnomalyTitle: FunctionComponent<IProps> = ({ anomalyEvents }) => {
    const anomalyPhrases = getAnomalyPhrases(anomalyEvents);
    return <h3 className="anomaly-title">{anomalyPhrases[0].toLocaleLowerCase()} Anomaly</h3>;
};

export default memo(AnomalyTitle);
