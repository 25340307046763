/**
 * Split enum names into words.
 *
 * @param value Enum name.
 * @returns     The title with added spaces.
 *
 * @example
 *
 * // Returns "Team Lead".
 * formatEnumName(Role.TeamLead)
 *
 */
export default function formatEnumName(value: string) {
    return value.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function formatServerEnumName(value: string): string {
    const words = value.split("_").map((word) => {
        return word[0].toUpperCase().concat(word.substr(1).toLowerCase());
    });
    return words.join(" ");
}
