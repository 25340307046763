import { AxiosError } from "axios";

import { logError } from "./log-error";

interface ISocketError {
    readonly message: string;
}

// Error message if none was returned by the backend.
export const DEFAULT_SERVER_ERROR_MESSAGE = "An unknown error occurred.";

/**
 * Check if a backend error message is actually a non-empty string.
 * @returns True if the message is a non-empty string.
 */
function isNonEmptyString(data: string): boolean {
    return typeof data === "string" && data.trim().length > 0;
}

/**
 * Get an error message sent by the backend. This could be a HTTP or Socket error.
 * @param serverError    HTTP or Socket error.
 * @param defaultMessage Fallback if a server error message is not found.
 * @returns              Server error message string.
 */
export function getServerError(
    serverError: AxiosError | ISocketError,
    defaultMessage: string = DEFAULT_SERVER_ERROR_MESSAGE
): string {
    logError(serverError);

    if (typeof serverError === "undefined" || serverError === null) {
        return defaultMessage;
    }

    if ("response" in serverError && typeof serverError.response !== "undefined") {
        const { data, statusText } = serverError.response;
        return isNonEmptyString(data) ? data : isNonEmptyString(statusText) ? statusText : defaultMessage;
    } else if ("message" in serverError) {
        return isNonEmptyString(serverError.message) ? serverError.message : defaultMessage;
    } else {
        return defaultMessage;
    }
}
