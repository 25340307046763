import { IAnomalyEvent, ITextAnomalyEvent } from "@edgetier/watchtower-types";
import { compareAsc } from "date-fns";

/**
 * From an array of events, get all the phrases of an text anomaly event.
 * @param anomalyEvents Events inside an anomaly.
 * @returns             Array of phrases.
 */
export const getAnomalyPhrases = (anomalyEvents: IAnomalyEvent[]) => {
    return anomalyEvents
        .sort((one, two) => compareAsc(one.startDateTime, two.startDateTime))
        .map((anomalyEvent) => anomalyEvent.details)
        .filter((details): details is ITextAnomalyEvent => "phrase" in details)
        .map(({ phrase }) => phrase)
        .filter((phrase, index, phrases) => phrases.indexOf(phrase) === index);
};
