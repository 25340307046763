import Qs from "qs";
import { FunctionComponent, memo } from "react";
import { Field, Form, Formik } from "formik";
import { trimOrNull } from "@edgetier/utilities";
import { DateFilter } from "@edgetier/client-components";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { startOfWeek, endOfWeek } from "date-fns";
import { Button } from "@edgetier/components";
import { faTag } from "@fortawesome/free-solid-svg-icons";

import ExploreSearchHelp from "./explore-search-help";
import SelectLanguage from "./select-language";
import SelectTag from "./select-tag";
import { trackUser } from "utilities/track-user";
import { parseUrlDateTime } from "utilities/parse-url-date-time";

import { DEFAULT_LANGUAGE_ISO_CODE } from "./explore-filters.constants";
import { IProps } from "./explore-filters.types";
import "./explore-filters.scss";

/**
 * Filters to explore phrases.
 * @returns Form with text, language, and date inputs.
 */
const ExploreFilters: FunctionComponent<IProps> = () => {
    const history = useHistory();
    const location = useLocation();
    const searchParameters = Qs.parse(location.search, { ignoreQueryPrefix: true });
    const { endDateTime, languageIsoCode, phrase, startDateTime, tag } = searchParameters;

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                languageIsoCode: typeof languageIsoCode === "string" ? languageIsoCode : DEFAULT_LANGUAGE_ISO_CODE,
                phrase: typeof phrase === "string" ? phrase : "",
                startDateTime: parseUrlDateTime(startDateTime, startOfWeek(new Date())),
                endDateTime: parseUrlDateTime(endDateTime, endOfWeek(new Date())),
                tag: typeof tag === "string" ? [tag] : tag,
            }}
            onSubmit={(values) => {
                trackUser("Search", values, true);

                const search = Qs.stringify(
                    { ...searchParameters, ...values, phrase: trimOrNull(values.phrase) },
                    { addQueryPrefix: true, arrayFormat: "repeat", skipNulls: true }
                );

                history.push({ search });
            }}
        >
            <Form className="explore-filters">
                <div className="field field-inline">
                    <div className="field-inline__icon">
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <Field
                        autoComplete="off"
                        autoFocus={true}
                        name="phrase"
                        placeholder="Phrase(s)&hellip;"
                        type="text"
                    />
                    <ExploreSearchHelp />
                </div>

                <div className="field field-inline">
                    <div className="field-inline__icon">
                        <FontAwesomeIcon icon={faTag} />
                    </div>
                    <SelectTag name="tag" />
                </div>

                <DateFilter startDateName="startDateTime" endDateName="endDateTime" />

                <div className="field field-inline">
                    <div className="field-inline__icon">
                        <FontAwesomeIcon icon={faGlobeAmericas} />
                    </div>
                    <SelectLanguage name="languageIsoCode" />
                </div>

                <Button icon={faSearch} type="submit">
                    Search
                </Button>
            </Form>
        </Formik>
    );
};

export default memo(ExploreFilters);
