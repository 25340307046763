import { getAnomalyPhrases } from "utilities/get-anomaly-phrases";
import { IAnomalyEvent } from "@edgetier/watchtower-types";
import { MAXIMUM_PHRASES } from "./anomaly-description.constants";

/**
 * Format phrases for a description. There can sometimes be too many phrases to list so only some are listed and the
 * rest are hidden behind a "and 2 more" type description.
 * @param anomalyEvents All events that have happened in an anomaly.
 * @returns             Phrases to list, and details of how to abbreviate the list.
 */
export const formatPhrases = (anomalyEvents: IAnomalyEvent[]) => {
    const anomalyPhrases = getAnomalyPhrases(anomalyEvents).filter(
        (anomalyPhrase, index, anomalyPhrases) => anomalyPhrases.indexOf(anomalyPhrase) === index
    );

    const phraseCount = anomalyPhrases.length;

    // There is no point in ever show "1 more" rather than just the phrase itself, so the display will only ever be
    // shortened if there are at least two more than the maximum allowed.
    const excessPhrases = phraseCount - MAXIMUM_PHRASES === 1 ? 2 : phraseCount - MAXIMUM_PHRASES;
    const isAbbreviated = excessPhrases > 0;
    const listedPhrases = isAbbreviated ? anomalyPhrases.slice(0, phraseCount - excessPhrases) : anomalyPhrases;

    return { excessPhrases, listedPhrases, isAbbreviated };
};
