import { ClientSignOut } from "@edgetier/client-components";
import { FunctionComponent, memo } from "react";
import { useHistory } from "react-router-dom";
import { Route } from "@edgetier/watchtower-types";

import Page from "constants/page";
import { IProps } from "./sign-out.types";
import "./sign-out.scss";
import { axios } from "utilities/axios";

/**
 * Navigation button to log out of the application.
 * @returns Sign out button.
 */
const SignOut: FunctionComponent<IProps> = () => {
    const history = useHistory();

    /**
     * Sign the user out. This makes a backend request although it can't really wait for the response as even in case of
     * a failure the user has to be signed out.
     */
    const signOut = () => {
        history.push(Page.Login);
        axios.delete(Route.TokensRefresh);
    };

    return <ClientSignOut onClick={signOut} />;
};

export default memo(SignOut);
