import { getDaysInMonth, eachMonthOfInterval } from "date-fns";

/**
 * Get the months in the filter range. The relatively width of each is also calculated depending on the number of days
 * in each.
 * @param firstDateTime Opening date in the filter's range.
 * @param lastDateTime  Closing date in the filter's range.
 * @returns             Array with one element per month containing a date in that month and the month's size.
 */
export const getMonths = (firstDateTime: Date, lastDateTime: Date) => {
    return eachMonthOfInterval({ start: firstDateTime, end: lastDateTime }).map((dateTime) => ({
        dateTime,
        dayCount: getDaysInMonth(dateTime),
    }));
};
