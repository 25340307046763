import { axios } from "utilities/axios";
import { FunctionComponent, memo } from "react";
import { ILanguagesGetResponse, Route } from "@edgetier/watchtower-types";
import { SelectField } from "@edgetier/client-components";
import { useQuery } from "react-query";

import { IProps } from "./select-language.types";
import "./select-language.scss";

const SelectLanguage: FunctionComponent<IProps> = ({ name }) => {
    const { data, isFetching } = useQuery(Route.Languages, () =>
        axios
            .get<ILanguagesGetResponse>(Route.Languages)
            .then(({ data }) => data.items.sort((one, two) => one.language.localeCompare(two.language)))
    );

    return (
        <SelectField
            isLoading={isFetching}
            options={data || []}
            name={name}
            setValue={({ languageIsoCode }) => languageIsoCode}
            getOptionLabel={({ language }) => language}
            isClearable={false}
            noResultMessage="No languages found"
            placeholder="Select language&hellip;"
        />
    );
};

export default memo(SelectLanguage);
