import React, { FunctionComponent } from "react";

import { IProps } from "./client-application.types";

/**
 * Wrapper around client application. This is mostly for applying top-level styles.
 * @param props.children Application content.
 * @returns              Container.
 */
const ClientApplication: FunctionComponent<IProps> = ({ children }) => {
    return <div className="client-application">{children}</div>;
};

export default ClientApplication;
