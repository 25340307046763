enum Page {
    Anomalies = "/main/anomalies",
    Anomaly = "/main/anomaly",
    Authenticate = "/authenticate",
    Explore = "/main/explore",
    Main = "/main",
    Login = "/",
}

export default Page;
