import { Dictionary, map } from "lodash";

interface IOptions {
    readonly key?: string;
    readonly quote?: boolean;
    readonly sort?: boolean;
}

/**
 * Create a comma separated list with a separator like "and" or "or".
 *
 * @param conjunction And/or etc. used to split words.
 * @param quote       Wrap each item in quotes.
 * @param sort        Whether to alphabetically sort or not.
 * @returns           String containing items.
 */
function formatList(conjunction: string, items: string[] | Dictionary<string>[], options: IOptions = {}) {
    if (items.length === 0) {
        return "";
    }

    const { key, quote, sort } = options;

    const values = (key
        ? map<Dictionary<string>, string>(items as { [index: string]: string }[], key)
        : items) as string[];

    // Sort the values alphabetically.
    const sorted =
        sort === true
            ? values.slice().sort(function (one: string, two: string): number {
                  return one.toLowerCase().localeCompare(two.toLowerCase());
              })
            : values;

    const quoteValues = quote === true ? sorted.map((item: string) => `"${item}"`) : sorted;

    if (quoteValues.length <= 1) {
        return quoteValues[0].toString();
    }

    const initial = quoteValues.slice(0, items.length - 1);
    return `${initial.join(", ")} ${conjunction} ${quoteValues[items.length - 1]}`;
}

export const andList = formatList.bind(null, "and");
export const orList = formatList.bind(null, "or");
export const ampersandList = formatList.bind(null, "&");
