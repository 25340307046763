export const markdownCharacters = [
    "!",
    '"',
    "#",
    "$",
    "%",
    "&",
    "'",
    "(",
    ")",
    "*",
    "+",
    ",",
    ".",
    "/",
    ":",
    ";",
    "<",
    "=",
    ">",
    "?",
    "@",
    "[",
    "\\",
    "]",
    "^",
    "_",
    "`",
    "{",
    "|",
    "}",
    "~",
    "-", // The hyphen has to be first or last because otherwise the regex will think it's a character range.
];

const markdownCharacterRegex = new RegExp(`[${"\\" + markdownCharacters.join("\\")}]`, "g");

/**
 * Escape any markdown characters in a text string.
 * @param text
 * @returns A string with all markdown characters escaped e.g "# a heading" returns "\# a heading"
 */
export const escapeMarkdown = (text: string) => {
    return text.replace(markdownCharacterRegex, "\\$&");
};
