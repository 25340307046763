import React, { useEffect } from "react";
import { Field } from "formik";

import SelectEnum from "../../select-enum";
import { TimeUnit } from "../date-filter.types";
import { relativeFromDate, relativeToDate } from "../utilities";

import { IRelativeDateRangeInput } from "./relative-date-range-input.types";

/**
 * RelativeDateRangeInput allows a sliding range to be selected that will be different each day the range is applied
 * Examples:
 *  - 3 weeks ago -> 1 week ago - will be a different range every day
 *  - 0 values: "from 0 weeks ago" is the start of the current week; "to 0 weeks ago" is the end of the current week
 *
 * Changes to the from/to ranges result in updates to the startDate and endDate definitions
 * @param from          Relative starting definition.
 * @param to            Relative end definition.
 * @param setFieldValue This is Formik's setFieldValue used to update startDate/endDate.
 * @param timeUnitRange Smallest and largest date period allowed.
 */
const RelativeDateRangeInput: IRelativeDateRangeInput = function RelativeDateRangeInput({
    setFieldValue,
    timeUnitRange,
    from,
    to,
}) {
    useEffect(() => {
        setFieldValue("startDate", relativeFromDate(new Date(), from.value, from.timeUnitId));
    }, [setFieldValue, from.value, from.timeUnitId]);
    useEffect(() => {
        setFieldValue("endDate", relativeToDate(new Date(), to.value, to.timeUnitId));
    }, [setFieldValue, to.value, to.timeUnitId]);

    // Exclude options outside the acceptable range.
    const excludeOptions = Object.values(TimeUnit).filter(
        (value): value is number => typeof value === "number" && (value < timeUnitRange[0] || value > timeUnitRange[1])
    );

    return (
        <div className="relative-date-range-input">
            <div className="relative-date-range-input__label">From</div>
            <div className="field field-inline">
                <Field type="number" name="relativeDates.from.value" min="0" required />
            </div>
            <div className="field field-inline">
                <SelectEnum
                    excludeOptions={excludeOptions}
                    getOptionLabel={(option) => `${option.label} ago`}
                    name="relativeDates.from.timeUnitId"
                    isClearable={false}
                    options={TimeUnit}
                />
            </div>
            <div className="field field-inline">
                <Field type="time" name="relativeDates.from.time" required />
            </div>

            <div className="relative-date-range-input__label">To</div>
            <div className="field field-inline">
                <Field type="number" name="relativeDates.to.value" min="0" required />
            </div>
            <div className="field field-inline">
                <SelectEnum
                    excludeOptions={excludeOptions}
                    getOptionLabel={(option) => `${option.label} ago`}
                    name="relativeDates.to.timeUnitId"
                    isClearable={false}
                    options={TimeUnit}
                />
            </div>
            <div className="field field-inline">
                <Field type="time" name="relativeDates.to.time" required />
            </div>
        </div>
    );
};

export default RelativeDateRangeInput;
