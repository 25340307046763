import React, { Fragment, FunctionComponent, memo } from "react";

import { IProps } from "./select-value-container-summary.types";

/**
 * Display a summary of the number of items selected.
 * @param props.description Text description of items being displayed.
 * @param props.valuesCount How many values have been selected.
 * @returns                 Summary text.
 */
const SelectValueContainerSummary: FunctionComponent<IProps> = ({ description, valuesCount }) => {
    return (
        <Fragment>
            {valuesCount} {description} selected
        </Fragment>
    );
};

export default memo(SelectValueContainerSummary);
