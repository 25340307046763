import { differenceInCalendarDays } from "date-fns";

/**
 * Get the opening size of the date range slider. This is so that when the user presses back or refreshes the page, the
 * slider is positioned correctly.
 * @param initialDateRange Full date range on the slider.
 * @param dateRange        Current date range selected.
 * @returns                Filter range dimensions.
 */
export const getDimensions = (width: number, initialDateRange: [Date, Date], dateRange: [Date, Date]) => {
    const initialDateRangeDays = differenceInCalendarDays(initialDateRange[1], initialDateRange[0]);
    const dateRangeDays = differenceInCalendarDays(dateRange[1], dateRange[0]);
    const dayWidth = Math.floor(width / initialDateRangeDays);
    const offset = differenceInCalendarDays(dateRange[0], initialDateRange[0]);

    return {
        dayWidth,
        sliderWidth: (dateRangeDays + 1) * dayWidth,
        sliderLeft: dayWidth * offset,
    };
};
