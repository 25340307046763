import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";

import DateRangeDisplay from "../date-range-display";
import RelativeDateRangeDisplay from "../relative-date-range-display";

import { IProps } from "./date-range-field.types";

const DateRangeField = React.forwardRef<HTMLDivElement, IProps>(
    ({ onClick, startDate, endDate, relativeDates }, ref) => {
        return (
            <div className="field field-inline date-filter" ref={ref} onClick={onClick}>
                <div className="date-filter__field">
                    <div className="field-inline__icon">
                        <FontAwesomeIcon icon={faCalendar} />
                    </div>
                    {relativeDates ? (
                        <RelativeDateRangeDisplay relativeDates={relativeDates} />
                    ) : (
                        <DateRangeDisplay startDate={startDate} endDate={endDate} />
                    )}
                </div>
            </div>
        );
    }
);

export default DateRangeField;
