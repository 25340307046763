export enum TimeUnit {
    Days = 4,
    Weeks = 5,
    Months = 6,
    Years = 7,
}

export enum ToggleDateType {
    Fixed,
    Relative,
}

export type IDateFilterPayload = {
    relativeDates?: IRelativeDates | null;
    startDate?: Date;
    endDate?: Date;
};

export type IDateFilter = React.FunctionComponent<DateFilterProps>;

export type DateFilterProps = {
    readonly relativeDateName?: string;
    readonly startDateName: string;
    readonly endDateName: string;
    readonly relativeTimeUnitRange?: [TimeUnit, TimeUnit];
};

export type IRelativeDates = {
    from: IRelativeDate;
    to: IRelativeDate;
};

export type IRelativeDate = {
    time: string;
    value: number;
    timeUnitId: TimeUnit.Days | TimeUnit.Weeks | TimeUnit.Months | TimeUnit.Years;
};

type AbstractFormValues = { [key: string]: any };
export type FormValues<T extends AbstractFormValues = {}> = T & AbstractFormValues;
