import classNames from "classnames";
import { FormattedDateTime } from "@edgetier/client-components";
import { FunctionComponent, memo } from "react";
import { trackUser } from "utilities/track-user";

import { IProps } from "./anomaly-interactions-table.types";
import "./anomaly-interactions-table.scss";

/**
 * Display interactions matching the search in a table with matches highlighted.
 * @param props.hasNextPage  Whether more rows are available. This affects the styling slightly.
 * @param props.interactions Array of interactions to display in the table.
 * @returns                  Table of interactions.
 */
const AnomalyInteractionsTable: FunctionComponent<IProps> = ({ hasNextPage = false, interactions }) => {
    return (
        <div
            className={classNames("anomaly-interactions-table", {
                "anomaly-interactions-table--can-fetch-more": hasNextPage,
            })}
        >
            {interactions.map(({ interactionId, searchMatch, startDateTime, url }) => (
                <a
                    className="anomaly-interactions-table__row"
                    href={url}
                    key={interactionId}
                    rel="noopener noreferrer"
                    onClick={() => trackUser("View Interaction Externally", { interactionId }, true)}
                    target="_blank"
                >
                    <FormattedDateTime dateTime={startDateTime} />
                    <div
                        className="anomaly-interactions-table__content"
                        dangerouslySetInnerHTML={{ __html: searchMatch }}
                    />
                </a>
            ))}
        </div>
    );
};

export default memo(AnomalyInteractionsTable);
