import urlJoin from "url-join";
import { FunctionComponent, memo, useMemo } from "react";
import { addHours, subHours, startOfHour, endOfHour } from "date-fns";
import { Box } from "@edgetier/client-components";
import { FormattedDateTime } from "@edgetier/client-components";
import { Link } from "react-router-dom";
import { SpinnerUntil } from "@edgetier/components";
import { TimePeriodGroupBy } from "@edgetier/watchtower-types";

import AnomalyDescription from "./anomaly-description";
import AnomalyTitle from "./anomaly-title";
import PhraseCountsChart from "components-for/anomalies/phrase-counts-chart";
import { getAnomalyPhrases } from "utilities/get-anomaly-phrases";
import { useAnomalyEvents } from "components-for/anomalies/use-anomaly-events";
import { useModel } from "components-for/anomalies/use-model";
import Page from "constants/page";

import { IProps } from "./anomaly-summary.types";
import "./anomaly-summary.scss";

/**
 * Show details of an anomaly that occurred or is ongoing.
 * @returns Anomaly title, description, and chart.
 */
const AnomalySummary: FunctionComponent<IProps> = ({ anomaly }) => {
    const anomalyEventsQuery = useAnomalyEvents(anomaly.anomalyId, { retry: false });
    const { data: model, isLoading: modelIsLoading } = useModel(anomaly.modelId, { retry: false });

    // Calculate the range of the chart. It will show the occurences of the relevant phrases several days before and
    // after the start of the anomaly.
    const [chartStartDateTime, chartEndDateTime] = useMemo(
        () => [startOfHour(subHours(anomaly.startDateTime, 120)), endOfHour(addHours(anomaly.startDateTime, 72))],
        [anomaly.startDateTime]
    );

    return (
        <Box className="anomaly-summary" innerPadding={true}>
            <SpinnerUntil data={anomalyEventsQuery.data} isReady={!anomalyEventsQuery.isLoading && !modelIsLoading}>
                {!Array.isArray(anomalyEventsQuery.data) || !model ? (
                    "Error"
                ) : (
                    <Link to={urlJoin(Page.Anomaly, anomaly.anomalyId)}>
                        <div className="anomaly-summary__details">
                            <div className="anomaly-summary__titles">
                                <AnomalyTitle anomalyEvents={anomalyEventsQuery.data} />
                                <div className="anomaly-summary__dates">
                                    <FormattedDateTime dateTime={anomaly.startDateTime} />
                                </div>
                            </div>

                            {model.model && <div className="anomaly-summary__model-name">{model.model}</div>}
                            <AnomalyDescription anomalyEvents={anomalyEventsQuery.data} />
                        </div>
                        <div className="anomaly-summary__chart">
                            <PhraseCountsChart
                                endDateTime={chartEndDateTime}
                                groupBy={TimePeriodGroupBy.Days}
                                highlightStartDateTime={anomaly.startDateTime}
                                highlightEndDateTime={anomaly.endDateTime}
                                isPreview={true}
                                languageIsoCode={
                                    "languageIsoCode" in model.configuration
                                        ? model.configuration.languageIsoCode
                                        : "en"
                                }
                                phrases={getAnomalyPhrases(anomalyEventsQuery.data)}
                                startDateTime={chartStartDateTime}
                                tags={model.tags}
                            />
                        </div>
                    </Link>
                )}
            </SpinnerUntil>
        </Box>
    );
};

export default memo(AnomalySummary);
