import { FunctionComponent, memo, useMemo } from "react";
import { andList } from "@edgetier/client-components";

import { formatPhrases } from "./anomaly-description.utilities";

import { IProps } from "./anomaly-description.types";
import "./anomaly-description.scss";

/**
 * Summarise an anomaly.
 * @param props.anomalyEvents All events that have happened in an anomaly.
 * @returns                   Description of an anomaly.
 */
const AnomalyDescription: FunctionComponent<IProps> = ({ anomalyEvents }) => {
    const { excessPhrases, listedPhrases, isAbbreviated } = useMemo(() => formatPhrases(anomalyEvents), [
        anomalyEvents,
    ]);

    return (
        <div className="anomaly-description">
            <p>
                Increase in interactions about {andList(listedPhrases, { quote: true })}
                {isAbbreviated && <> and {excessPhrases} more terms</>}.
            </p>
        </div>
    );
};

export default memo(AnomalyDescription);
