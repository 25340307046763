import { FunctionComponent, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";

import { IProps } from "./anomaly-date-filter-handle.types";
import "./anomaly-date-filter-handle.scss";

const AnomalyDateFilterHandle: FunctionComponent<IProps> = () => {
    return (
        <div className="anomaly-date-filter-handle">
            <FontAwesomeIcon icon={faGripVertical} />
        </div>
    );
};

export default memo(AnomalyDateFilterHandle);
