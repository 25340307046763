import classNames from "classnames";
import { Cell, BarChart, XAxis, YAxis, Bar, ResponsiveContainer, Tooltip } from "recharts";
import { format } from "date-fns";
import { Fragment, FunctionComponent, memo, useMemo } from "react";
import { SpinnerUntil } from "@edgetier/components";
import { IInteractionsGetQueryParameters, Route, TimePeriodGroupBy } from "@edgetier/watchtower-types";
import { useQuery } from "react-query";

import { useTimeZone } from "components-for/time-zones/use-time-zone";

import PhraseCountsChartTooltip from "./phrase-counts-chart-tooltip";
import { formatChartData, formatLabel, getPhraseCounts } from "./phrase-counts-chart.utilities";
import { IProps } from "./phrase-counts-chart.types";
import "./phrase-counts-chart.scss";

/**
 * Chart of how many interactions contained a phrase or phrases in a given time period.
 */
const PhraseCountsChart: FunctionComponent<IProps> = ({
    endDateTime,
    highlightEndDateTime,
    highlightRange = true,
    highlightStartDateTime,
    isPreview = false,
    languageIsoCode,
    groupBy,
    proportion = false,
    phrases,
    startDateTime,
    tags,
}) => {
    const { data: timeZone, isLoading: isTimeZoneLoading } = useTimeZone();
    const parameters: IInteractionsGetQueryParameters = {
        languageIsoCode,
        endDateTime: endDateTime ?? undefined,
        groupBy,
        startDateTime,
        search: phrases.join(" or "),
        tag: tags,
        timeZone,
        webSearchSyntax: true,
    };
    const query = useQuery([Route.Interactions, parameters], () => getPhraseCounts(Route.Interactions, parameters), {
        enabled: typeof timeZone === "string",
        retry: !isPreview,
    });

    const chartData = useMemo(
        () => formatChartData(query.data, highlightStartDateTime, highlightEndDateTime, groupBy),
        [highlightEndDateTime, highlightStartDateTime, query.data, groupBy]
    );

    return (
        <div className="phrase-counts-chart">
            <SpinnerUntil<typeof chartData> data={chartData} isReady={!query.isLoading && !isTimeZoneLoading}>
                {(data) =>
                    data.length > 14 * 24 ? (
                        <div className="phrase-counts-chart__excess">Too much data to display</div>
                    ) : (
                        <ResponsiveContainer height="100%" width="100%">
                            <BarChart data={query.data}>
                                {!isPreview && (
                                    <XAxis
                                        xAxisId={0}
                                        interval={groupBy === TimePeriodGroupBy.Hours ? 5 : "preserveStart"}
                                        dataKey="dateTime"
                                        tickFormatter={(dateTime) => formatLabel(groupBy, dateTime)}
                                        tick={{ fontSize: groupBy === TimePeriodGroupBy.Hours ? 10 : undefined }}
                                    />
                                )}

                                {!isPreview && groupBy === TimePeriodGroupBy.Hours && (
                                    <XAxis
                                        xAxisId={1}
                                        axisLine={false}
                                        dataKey="dateTime"
                                        scale="band"
                                        interval={0}
                                        tick={({ x, y, payload }) => {
                                            const { offset, value } = payload;
                                            const hour = value.getHours();

                                            // Display label in the middle of each day.
                                            if (hour === 12) {
                                                return (
                                                    <text x={x} y={y} textAnchor="middle">
                                                        {format(value, "d MMM")}
                                                    </text>
                                                );
                                            }

                                            // Vertical line to separate days.
                                            if (hour === 23 && payload.index !== data.length - 1) {
                                                const pathX = Math.floor(x + offset) + 0.5;
                                                return <path d={`M${pathX},${y + 3}v-15`} />;
                                            }

                                            return <Fragment />;
                                        }}
                                    />
                                )}

                                {!isPreview && (
                                    <YAxis
                                        tickFormatter={(value) => (proportion ? `${(value * 100).toFixed(1)}%` : value)}
                                    />
                                )}
                                <Bar dataKey={proportion ? "proportion" : "count"}>
                                    {data.map(({ dateTime, isHighlighted }) => (
                                        <Cell
                                            key={dateTime.toISOString()}
                                            className={classNames({
                                                "phrase-counts-chart--is-highlighted": isHighlighted && highlightRange,
                                                "phrase-counts-chart--is-neutral": !highlightRange,
                                            })}
                                        />
                                    ))}
                                </Bar>
                                <Tooltip
                                    content={({ active, label, payload }) =>
                                        active &&
                                        Array.isArray(payload) &&
                                        typeof payload[0].value === "number" && (
                                            <PhraseCountsChartTooltip
                                                dateTime={payload[0].payload.dateTime}
                                                showTime={groupBy === TimePeriodGroupBy.Hours}
                                                value={payload[0].value}
                                            />
                                        )
                                    }
                                    isAnimationActive={false}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    )
                }
            </SpinnerUntil>
        </div>
    );
};

export default memo(PhraseCountsChart);
