import ReactSelect from "react-select";
import { FunctionComponent, memo } from "react";
import { Field, FieldProps } from "formik";

import { IAnomalyPhraseCountsSelectRangeOption, IProps } from "./anomaly-phrase-counts-select-range.types";
import "./anomaly-phrase-counts-select-range.scss";

/**
 * Allow users to filter the phrase count chart range.
 * @param props.options Range options.
 * @returns             Select input.
 */
const AnomalyPhraseCountsSelectRange: FunctionComponent<IProps> = ({ options }) => {
    return (
        <Field>
            {({ form }: FieldProps) => (
                <ReactSelect
                    className="react-select-container"
                    classNamePrefix="react-select"
                    getOptionLabel={({ label }) => label}
                    isClearable={false}
                    options={options}
                    onChange={(option) => {
                        const { value } = option as IAnomalyPhraseCountsSelectRangeOption;
                        form.setFieldValue("startDateTime", value.startDateTime);
                        form.setFieldValue("endDateTime", value.endDateTime);
                    }}
                    value={options.find(
                        ({ value }) =>
                            value.startDateTime === form.values.startDateTime &&
                            value.endDateTime === form.values.endDateTime
                    )}
                />
            )}
        </Field>
    );
};

export default memo(AnomalyPhraseCountsSelectRange);
