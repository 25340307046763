import { FunctionComponent, memo } from "react";

import AnomalyInteractions from "components-for/interactions/anomaly-interactions";
import AnomalyPhraseCountsChart from "pages/anomaly/anomaly-phrase-counts-chart";

import { useExploreParameters } from "./use-explore-parameters";
import { IProps } from "./explore-results.types";
import "./explore-results.scss";

/**
 * Take search parameters from the URL and call the backend with them to show a chart and table.
 * @returns Chart and table.
 */
const ExploreResults: FunctionComponent<IProps> = () => {
    const { endDateTime, languageIsoCode, phrase, startDateTime, tags } = useExploreParameters();

    return (
        <div className="explore-results">
            {startDateTime instanceof Date && typeof phrase === "string" ? (
                <>
                    <AnomalyPhraseCountsChart
                        anomalyEndDateTime={endDateTime}
                        anomalyStartDateTime={startDateTime}
                        dateTime={new Date()}
                        filterRange={false}
                        highlightRange={false}
                        languageIsoCode={languageIsoCode || "en"}
                        phrases={phrase.split(" | ")}
                        tags={tags}
                    />

                    <AnomalyInteractions
                        endDateTime={endDateTime}
                        languageIsoCode={languageIsoCode || "en"}
                        phrases={phrase.split(" | ")}
                        startDateTime={startDateTime}
                        tags={tags}
                    />
                </>
            ) : (
                <div className="explore-results__placeholder">
                    No results. Please enter a search term and select a date range&hellip;
                </div>
            )}
        </div>
    );
};

export default memo(ExploreResults);
