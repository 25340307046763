import {
    IInteractionsCountResponse,
    IInteractionsGetQueryParameters,
    TimePeriodGroupBy,
} from "@edgetier/watchtower-types";
import { axios } from "utilities/axios";
import { format, startOfDay, endOfDay, startOfHour, endOfHour } from "date-fns";

type Await<T> = T extends PromiseLike<infer U> ? U : T;

const DAILY_DATE_TIME_FORMAT = "d MMM";
const HOURLY_DATE_TIME_FORMAT = "HH";

export const formatLabel = (groupBy: TimePeriodGroupBy | undefined, dateTime: Date) => {
    return format(dateTime, groupBy === TimePeriodGroupBy.Hours ? HOURLY_DATE_TIME_FORMAT : DAILY_DATE_TIME_FORMAT);
};

/**
 * Ask the backend to count the number of phrase occurences per day.
 * @param url             API URL.
 * @param queryParameters Filters for backend calculation.
 * @returns               Promise of count data.
 */
export const getPhraseCounts = async (url: string, queryParameters: IInteractionsGetQueryParameters) => {
    const response = await axios.get<IInteractionsCountResponse>(url, { params: queryParameters });
    return response.data.items;
};

/**
 * Transform the interaction count data to be suitable for graphing.
 * @param queryData              Result of requesting interaction counts.
 * @param highlightStartDateTime Start of the date range to highlight.
 * @param highlightEndDateTime   End of the date range to highlight.
 * @param groupBy                Period to group results (hours, days etc.).
 * @returns                      Array of data with additional properties.
 */
export const formatChartData = (
    queryData: Await<ReturnType<typeof getPhraseCounts>> | undefined,
    highlightStartDateTime: Date | undefined,
    highlightEndDateTime: Date | null | undefined,
    groupBy: TimePeriodGroupBy
) => {
    const getStartOf = groupBy === TimePeriodGroupBy.Hours ? startOfHour : startOfDay;
    const getEndOf = groupBy === TimePeriodGroupBy.Hours ? endOfHour : endOfDay;
    return (queryData ?? []).map((row) => ({
        ...row,
        isHighlighted:
            highlightStartDateTime instanceof Date
                ? row.dateTime >= getStartOf(highlightStartDateTime) &&
                  row.dateTime <= getEndOf(highlightEndDateTime ?? new Date())
                : false,
    }));
};
