import React, { FunctionComponent, memo } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IProps } from "./navigation-link.types";

const NavigationLink: FunctionComponent<IProps> = ({ label, icon, page }) => {
    return (
        <li className="navigation-link">
            <NavLink className="navigation-link__inner" to={page}>
                <FontAwesomeIcon fixedWidth={true} icon={icon} />
                <div>{label}</div>
            </NavLink>
        </li>
    );
};

export default memo(NavigationLink);
