import Axios from "axios";
import Qs from "qs";
import { decamelizeKeys } from "humps";
import { getAccessToken, setAccessToken } from "utilities/access-token";
import { ITokensPostResponse, Route } from "@edgetier/watchtower-types";
import { isValid, parseISO } from "date-fns";

export const BASE_URL = "/api";

const axios = Axios.create({
    baseURL: BASE_URL,
    paramsSerializer: (parameters) =>
        Qs.stringify(decamelizeKeys(parameters), {
            arrayFormat: "repeat",
            skipNulls: true,
        }),
    transformResponse: (data) => {
        try {
            return JSON.parse(data, (_key, value) => {
                // Attempt to transform strings to dates.
                if (typeof value === "string") {
                    try {
                        const date = parseISO(value);
                        return isValid(date)
                            ? parseISO(
                                  value.replace(
                                      /([+-])(\d{2}:\d{2})/,
                                      (_, sign, offset) => `${sign === "+" ? "-" : "+"}${offset}`
                                  )
                              )
                            : value;
                    } catch {
                        return value;
                    }
                }
                return value;
            });
        } catch {
            return data;
        }
    },
});

/**
 * Put the access token in a header.
 */
export const createAuthorisationHeader = () => `Bearer ${getAccessToken()}`;

/**
 * Get a new token. The refresh token is stored in a cookie and can be used to generate a new access token.
 */
export const refreshToken = async () => {
    const response = await axios.post<ITokensPostResponse>(Route.TokensRefresh);
    const { accessToken } = response.data;
    setAccessToken(accessToken);
    return accessToken;
};

axios.interceptors.request.use((request) => {
    request.headers["Authorization"] = createAuthorisationHeader();
    return request;
});

export { axios };
