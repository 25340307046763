import Page from "constants/page";
import urlJoin from "url-join";

/**
 * For authentication, Cognito needs to know to what URL a user will be redirected after login. This URL depends on the 
 * environment (production, pre-production, localhost etc.).
 * @returns Authentication URL.
 */
export const getRedirectUri = () => {
    const { protocol, host } = window.location;
    return urlJoin(`${protocol}//${host}`, Page.Authenticate);
};
