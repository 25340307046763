import React from "react";
import { ActionMeta, ValueType } from "react-select";
import { Field, FieldProps, FormikActions } from "formik";
import { isArray } from "@edgetier/utilities";

import Select from "../select";

import { IProps } from "./select-field.types";

/**
 * Wrapper around React Select to make it work nicely with Formik.
 */
export default class SelectField<T, V = number> extends React.PureComponent<IProps<T, V>> {
    static defaultProps = {
        unsetClearValue: null,
    };

    /**
     * Figure out what value was selected, if any.
     * @param fieldValue Current field value in Formik.
     * @returns          The selected value.
     */
    getValue = (fieldValue: V): T | T[] | null | undefined => {
        const { options, setValue } = this.props;

        // Find the matching value in the list of options.
        const value =
            this.props.isMulti && Array.isArray(fieldValue)
                ? options.filter((option) => fieldValue.includes(setValue(option)))
                : options.find((option) => this.isEqual(setValue(option), fieldValue));

        // Return the value or null if there is no valid selected value.
        return typeof value === "undefined" ? null : value;
    };

    /**
     * Check if an option equals the field value. This can be overwritten with a prop.
     * @param option     Option value.
     * @param fieldValue Current field value in Formik.
     * @returns          True if the values are equal.
     */
    isEqual(option: V, fieldValue: V): boolean {
        return typeof this.props.isEqual === "function"
            ? this.props.isEqual(option, fieldValue)
            : option === fieldValue;
    }

    /**
     * Remove the focus state when the select input is blurred.
     * @param blurEvent Browser blur event.
     */
    onBlur(setFieldTouched: FormikActions<T>["setFieldTouched"]): void {
        setFieldTouched(this.props.name, true);
    }

    /**
     * Handle changes to the selection.
     * @param values Value or values from the select input.
     */
    onChange(setFieldValue: FormikActions<T>["setFieldValue"], values: ValueType<T>, _: ActionMeta<T>): void {
        if (values === null || typeof values === "undefined") {
            setFieldValue(this.props.name, this.props.clearWithUndefined ? undefined : null);
        } else {
            if (isArray(values)) {
                setFieldValue(this.props.name, values.map(this.props.setValue));
            } else {
                setFieldValue(this.props.name, this.props.setValue(values));
            }
        }
        if (this.props.onChange) this.props.onChange(values);
    }

    /**
     * Display a Formik field wrapped around a select component.
     */
    render(): React.ReactNode {
        const { name, noResultMessage, setValue, validate, ...otherProps } = this.props;
        return (
            <Field name={name} validate={validate}>
                {({ field, form }: FieldProps) => (
                    <Select
                        inputId={name}
                        {...otherProps}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        getOptionValue={(item: any) => String(setValue(item))}
                        noOptionsMessage={() => noResultMessage || "Nothing found"}
                        onBlur={this.onBlur.bind(this, form.setFieldTouched)}
                        onChange={this.onChange.bind(this, form.setFieldValue)}
                        options={this.props.options}
                        value={this.getValue(field.value)}
                    />
                )}
            </Field>
        );
    }
}
