import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { faTimes, faCheck, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { Radio, Button, FieldError } from "@edgetier/components";

import { ToggleDateType, IRelativeDates, IRelativeDate, TimeUnit } from "../date-filter.types";
import DateRangeDisplay from "../date-range-display";
import FixedDateRangeInput from "../fixed-date-range-input";
import RelativeDateRangeInput from "../relative-date-range-input";
import ShortcutDateRanges from "../shortcut-date-ranges";
import { relativeFromDate, relativeToDate } from "../utilities";

import { IDateRangeInput, IDateRangeInputState } from "./date-range-input.types";

/**
 * DateRangeInput Holds state for two types of date pickers and shows one or the other IF "isToggleable" is true
 * @param closeFilter           A function to close the date filter.
 * @param endDate
 * @param isTogglable           Boolean, control whether to display the relativeDates and Fixed dates toggle.
 * @param onChange
 * @param relativeDates         If not present toggle will default to fixed, if present will default to relativeDates
 * @param relativeTimeUnitRange Smallest and largest date period allowed.
 * @param startDate
 */
const DateRangeInput: IDateRangeInput = function DateRangeInput({
    closeFilter,
    endDate,
    isTogglable = false,
    onChange,
    relativeDates,
    relativeTimeUnitRange,
    startDate,
}) {
    const [initialValues] = useState<IDateRangeInputState>(getInitialDates(startDate, endDate, relativeDates));

    const handleSubmit = ({ toggleValue, ...values }: IDateRangeInputState) => {
        const isFixedDates = toggleValue === ToggleDateType.Fixed;
        onChange({
            relativeDates: isFixedDates ? null : values.relativeDates,
            startDate: values.startDate,
            endDate: values.endDate,
        });
        if (closeFilter) closeFilter();
    };

    const clearValues = () => {
        onChange({
            relativeDates: null,
            startDate: undefined,
            endDate: undefined,
        });
        if (closeFilter) closeFilter();
    };

    return (
        <Formik<IDateRangeInputState>
            initialValues={initialValues}
            validationSchema={dateSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, setValues, values, submitForm, errors, touched, setErrors }) => {
                const isFixedDates = values.toggleValue === ToggleDateType.Fixed;

                return (
                    <div className="date-filter__input">
                        {isTogglable && (
                            <div className="date-filter__input__toggle">
                                <Radio name="toggleValue" option={ToggleDateType.Fixed} label="Fixed" />
                                <Radio name="toggleValue" option={ToggleDateType.Relative} label="Relative" />
                            </div>
                        )}

                        {isFixedDates && (
                            <>
                                <FixedDateRangeInput
                                    startDate={values.startDate}
                                    endDate={values.endDate}
                                    onChange={(partialValues) => {
                                        setValues({ ...values, ...partialValues });
                                        // We don't want to show error message until submit fires
                                        // then we want to clear it on next change
                                        setErrors({ endDate: undefined });
                                    }}
                                />
                                {errors.endDate && touched.endDate ? (
                                    <div className="date-filter__error">
                                        <FieldError name="endDate" />
                                    </div>
                                ) : (
                                    <DateRangeDisplay startDate={values.startDate} endDate={values.endDate} />
                                )}
                            </>
                        )}

                        {!isFixedDates && (
                            <>
                                <div className="date-filter__relative-date">
                                    <ShortcutDateRanges
                                        onSelect={(newRelativeDates) =>
                                            setValues({ ...values, relativeDates: newRelativeDates })
                                        }
                                        timeUnitRange={relativeTimeUnitRange}
                                    />
                                    <RelativeDateRangeInput
                                        {...values.relativeDates}
                                        setFieldValue={setFieldValue}
                                        timeUnitRange={relativeTimeUnitRange}
                                    />
                                </div>
                                <FieldError name="endDate" />
                                <DateRangeDisplay
                                    startDate={values.startDate}
                                    fromTime={values.relativeDates.from.time}
                                    endDate={values.endDate}
                                    toTime={values.relativeDates.to.time}
                                />
                            </>
                        )}

                        <div className="date-filter__select-date__buttons">
                            <Button
                                className="button button--negative button--reset"
                                icon={faUndoAlt}
                                onClick={clearValues}
                                styleName="negative"
                                type="button"
                            >
                                Clear
                            </Button>

                            {closeFilter && (
                                <Button icon={faTimes} onClick={closeFilter} styleName="neutral" type="button">
                                    Cancel
                                </Button>
                            )}

                            <Button icon={faCheck} styleName="positive" type="button" onClick={submitForm}>
                                Apply
                            </Button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

function getInitialDates(startDateIn?: Date, endDateIn?: Date, relativeDatesIn?: IRelativeDates) {
    const relativeDates = relativeDatesIn ?? {
        from: { timeUnitId: TimeUnit.Days, value: 3, time: "00:00" },
        to: { timeUnitId: TimeUnit.Days, value: 0, time: "23:59" },
    };

    return {
        relativeDates,
        startDate: startDateIn || relativeFromDate(new Date(), relativeDates.from.value, relativeDates.from.timeUnitId),
        endDate: endDateIn || relativeToDate(new Date(), relativeDates.to.value, relativeDates.to.timeUnitId),
        toggleValue: relativeDatesIn ? ToggleDateType.Relative : ToggleDateType.Fixed,
    };
}

const relativeDateSchema = Yup.object().shape<IRelativeDate>({
    time: Yup.string().required(),
    timeUnitId: Yup.number().required(),
    value: Yup.number().required(),
});

const dateSchema = Yup.object().shape<IDateRangeInputState>({
    relativeDates: Yup.object<IRelativeDates>({
        from: relativeDateSchema.required(),
        to: relativeDateSchema.required(),
    }),
    startDate: Yup.date().required("A starting date is required"),
    endDate: Yup.date()
        .min(Yup.ref("startDate"), "Starting date must be before ending date")
        .required("An ending date is required"),
    toggleValue: Yup.number().oneOf([ToggleDateType.Fixed, ToggleDateType.Relative]).required(),
});

export default DateRangeInput;
