import { FunctionComponent, memo } from "react";
import { Box } from "@edgetier/client-components";

import { IProps } from "./anomaly-summary-value.types";
import "./anomaly-summary-value.scss";

const AnomalySummaryValue: FunctionComponent<IProps> = ({ children, title }) => {
    return (
        <Box className="anomaly-summary-value">
            {title}
            <div className="anomaly-summary-value__value">{children}</div>
        </Box>
    );
};

export default memo(AnomalySummaryValue);
