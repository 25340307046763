import React, { FunctionComponent, memo } from "react";
import { Tooltip } from "@edgetier/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IProps } from "./explore-search-help.types";
import "./explore-search-help.scss";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

/**
 * Tooltip to explain search functionality.
 * @returns Question mark icon that will show a help tooltip on hover.
 */
const ExploreSearchHelp: FunctionComponent<IProps> = () => {
    return (
        <Tooltip
            content={
                <div className="explore-search-help__tooltip">
                    <p>Type one or more words/phrases to search.</p>

                    <ul>
                        <li>
                            Typing several terms separated by spaces e.g. <code>error payment</code> will find
                            interactions that contain all of those words.
                        </li>
                        <li>
                            Match any phrase with <code>OR</code>: <code>error OR issue</code> will find interactions
                            with either word.
                        </li>
                        <li>
                            Wrap in double quotes to search for multiple words appearing together e.g.{" "}
                            <code>"website down"</code>.
                        </li>
                        <li>
                            Exclude with <code>-</code>: <code>complaint -delivery</code> will find complaints not
                            mentioning &ldquo;delivery&rdquo;.
                        </li>
                    </ul>
                    <p>
                        Words are lemmatised so that, for example, searching for &ldquo;cancellation&rdquo; will also
                        show interactions containing &ldquo;cancelled&rdquo;, &ldquo;cancelling&rdquo; etc.
                    </p>
                </div>
            }
            possiblePlacements={["bottom-center"]}
        >
            <div className="explore-search-help">
                <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
        </Tooltip>
    );
};

export default memo(ExploreSearchHelp);
