import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons/faLongArrowAltRight";

import DateDisplay from "../date-display";

import { IDateRangeDisplay } from "./date-range-display.types";

const DateRangeDisplay: IDateRangeDisplay = function DateRangeDisplay({ startDate, endDate, fromTime, toTime }) {
    return (
        <div className="date-filter__range-display">
            <DateDisplay date={startDate} />
            {fromTime && <span className="date-filter__range-display__time">{fromTime}</span>}
            <FontAwesomeIcon icon={faLongArrowAltRight} />
            <DateDisplay date={endDate} />
            {toTime && <span className="date-filter__range-display__time">{toTime}</span>}
        </div>
    );
};

export default DateRangeDisplay;
