import { addDays, endOfDay, endOfToday, min, startOfDay, subDays } from "date-fns";

// How many days before and after an anomaly can be displayed.
export const RANGE_DAYS = [3, 7];

/**
 * Get options to filter the range of the phrase count chart.
 * @param dateTime Anomaly occurence time.
 */
export const getRangeOptions = (dateTime: Date) =>
    RANGE_DAYS.map((days) => ({
        label: `${days} days before & after`,
        value: {
            startDateTime: startOfDay(subDays(dateTime, days)),
            endDateTime: min([endOfDay(addDays(dateTime, days)), endOfToday()]),
        },
    }));
