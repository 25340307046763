import Qs from "qs";
import {
    AnomalyEventType,
    IAnomaliesGetQueryParameters,
    IAnomaliesGetResponse,
    ModelType,
} from "@edgetier/watchtower-types";
import { axios } from "utilities/axios";

// Request parameters.
const ANOMALIES_LIMIT = 6;
const ANOMALIES_ORDER_BY = "start_date_time.desc";

/**
 * Request anomalies inside a date range. There is an initial default date range when the page loads. If the user
 * changes the date filter then the URL is updated to contain the new dates.
 * @param url              Anomaly URL.
 * @param parameters       URL parameters.
 * @param initialDateRange Default opening and closing dates when the page loads.
 * @param nextPage         Optional URL of the next page to load.
 * @returns                Promise of an array of anomalies.
 */
export const requestAnomalies = async (
    url: string,
    parameters: Qs.ParsedQs,
    [startDateTime, endDateTime]: [Date, Date],
    nextPage: string | undefined
) => {
    const requestParameters: IAnomaliesGetQueryParameters = {
        anomalyEventType: AnomalyEventType.New,
        endDateTime,
        limit: ANOMALIES_LIMIT,
        modelType: ModelType.Text,
        orderBy: ANOMALIES_ORDER_BY,
        startDateTime,
        ...parameters,
    };

    // If loading the next page, the backend provides a full URL. Otherwise construct a search query.
    const request = nextPage
        ? axios.get<IAnomaliesGetResponse>(nextPage)
        : axios.get<IAnomaliesGetResponse>(url, { params: requestParameters });

    const response = await request;
    return response.data;
};
