import { faSignIn } from "@fortawesome/pro-solid-svg-icons";
import { FunctionComponent, memo } from "react";
import { Button } from "@edgetier/components";
import { ClientLogin } from "@edgetier/client-components";

import { createSignInUrl } from "./login.utilities";
import { IProps } from "./login.types";
import logo from "images/colour-light-background.svg";
import headerImage from "images/background-cover-image.jpg";
import "./login.scss";

/**
 * Login button that redirects to AWS Cognito when clicked.
 * @returns Sign-in button.
 */
const Login: FunctionComponent<IProps> = () => {
    /**
     * Generate a sign-in URL and redirect the user.
     */
    const signIn = async () => {
        const { codeVerifier, state, url } = await createSignInUrl();
        window.location.href = url;
        localStorage.setItem("authenticationState", state);
        localStorage.setItem("authenticationCodeVerifier", codeVerifier);
    };

    return (
        <ClientLogin headerImage={headerImage} logo={logo} title="WatchTower" version={process.env.REACT_APP_VERSION}>
            <Button icon={faSignIn} onClick={signIn}>
                Sign in
            </Button>
        </ClientLogin>
    );
};

export default memo(Login);
