import Axios from "axios";
import toast from "react-hot-toast";
import { useEffect, FunctionComponent, memo } from "react";
import { useHistory } from "react-router-dom";

import Page from "constants/page";
import { getServerError } from "utilities/get-server-error";
import { setAccessToken } from "utilities/access-token";

import { authenticate } from "./authenticate.utilities";
import { IProps } from "./authenticate.types";
import "./authenticate.scss";

/**
 * This is the "redirect URI" page in the authentication flow. Cognito calls this route with a code that can be used to
 * generate an access token.
 * @returns Essentially blank page for authenticating users.
 */
const Authenticate: FunctionComponent<IProps> = () => {
    const history = useHistory();

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const { accessToken } = await authenticate();
                setAccessToken(accessToken);
                history.push(Page.Anomalies);
            } catch (serverError) {
                if (Axios.isAxiosError(serverError)) {
                    toast.error(getServerError(serverError));
                }

                history.replace(Page.Login);
            } finally {
                localStorage.removeItem("authenticationState");
                localStorage.removeItem("authenticationCodeVerifier");
            }
        };

        getAccessToken();
    }, [history]);

    return <div className="authenticate">Authenticating&hellip;</div>;
};

export default memo(Authenticate);
