import { AxiosError } from "axios";
import { UseQueryOptions, useQuery } from "react-query";

import { axios } from "utilities/axios";
import { IAnomalyEvent, IAnomalyEventsGetResponse, Route } from "@edgetier/watchtower-types";

/**
 * Get all anomaly events for an anomaly.
 * @param url Start URL to get notes.
 */
const getAnomalyEvents = async (url: string) => {
    const response = await axios.get<IAnomalyEventsGetResponse>(url);
    return response.data.items;
};

/**
 * Get anomaly events for an anomaly.
 * @param anomalyId Anomaly events belong to an anomaly.
 * @returns         React Query result.
 */
export const useAnomalyEvents = (
    anomalyId: string,
    configuration: UseQueryOptions<IAnomalyEvent[], AxiosError<void>> = {}
) => {
    const url = Route.AnomalyEvents.replace(":anomalyId", anomalyId);
    return useQuery(url, () => getAnomalyEvents(url), {
        onError: (serverError: AxiosError<void>) => {
            console.error(serverError);
        },
        ...configuration,
    });
};
