import { Box } from "@edgetier/client-components";
import { FunctionComponent, memo, useMemo, useState } from "react";
import { TimePeriodGroupBy } from "@edgetier/watchtower-types";

import AnomalyPhraseCountsChartFilters from "./anomaly-phrase-counts-chart-filters";
import PhraseCountsChart from "components-for/anomalies/phrase-counts-chart";
import { IAnomalyPhraseCountsChartFilters } from "./anomaly-phrase-counts-chart-filters/anomaly-phrase-counts-chart-filters.types";
import { IProps } from "./anomaly-phrase-counts-chart.types";
import { getRangeOptions } from "./anomaly-phrase-counts-chart.utilities";
import "./anomaly-phrase-counts-chart.scss";

/**
 * Chart the number of interactions affected by an anomaly.
 * @param props.dateTime Date of the anomaly first occurring.
 * @param props.phrases  Phrases mentioned in the anomaly.
 * @returns              Chart and filters.
 */
const AnomalyPhraseCountsChart: FunctionComponent<IProps> = ({
    anomalyEndDateTime,
    anomalyStartDateTime,
    dateTime,
    filterRange = true,
    highlightRange,
    languageIsoCode,
    phrases,
    tags,
}) => {
    const rangeOptions = useMemo(() => getRangeOptions(dateTime), [dateTime]);
    const [filters, setFilters] = useState<IAnomalyPhraseCountsChartFilters>({
        groupBy: TimePeriodGroupBy.Days,
        proportion: false,
        ...rangeOptions[0].value,
    });

    return (
        <Box className="anomaly-phrase-counts-chart">
            <div className="anomaly-phrase-counts-chart__header">
                <h3>Interactions</h3>
                <AnomalyPhraseCountsChartFilters
                    filterRange={filterRange}
                    initialValues={filters}
                    onChange={setFilters}
                    rangeOptions={rangeOptions}
                />
            </div>
            <div className="anomaly-phrase-counts-chart__chart">
                <PhraseCountsChart
                    highlightEndDateTime={anomalyEndDateTime}
                    highlightRange={highlightRange}
                    highlightStartDateTime={anomalyStartDateTime}
                    languageIsoCode={languageIsoCode}
                    phrases={phrases}
                    tags={tags}
                    {...filters}
                    {...(!filterRange ? { startDateTime: anomalyStartDateTime, endDateTime: anomalyEndDateTime } : {})}
                />
            </div>
        </Box>
    );
};

export default memo(AnomalyPhraseCountsChart);
