import React, { FunctionComponent, memo } from "react";

import { IProps } from "./client-login.types";

/**
 * Login page. Client applications can provide their own buttons.
 * @param props.children    Login content.
 * @param props.headerImage Image for the banner at the top of the page.
 * @param props.logo        EdgeTier logo.
 * @param props.title       Application title.
 * @param props.version     Optional application version.
 * @returns                 Login display.
 */
const ClientLogin: FunctionComponent<IProps> = ({ children, headerImage, logo, title, version }) => {
    return (
        <>
            <div className="client-login__background" style={{ backgroundImage: `url(${headerImage})` }} />
            <div className="client-login">
                <div className="client-login__container">
                    <img src={logo} alt="EdgeTier" />
                    <h1>{title}</h1>
                    {children}
                </div>
                {typeof version === "string" && <div className="client-login__version">v{version}</div>}
            </div>
        </>
    );
};

export default memo(ClientLogin);
