import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { FunctionComponent, memo } from "react";
import { ITag, Route } from "@edgetier/watchtower-types";
import { SelectField } from "@edgetier/client-components";
import { useQuery } from "react-query";

import { getAllPaginatedItems } from "utilities/get-all-paginated-items";
import { getServerError } from "utilities/get-server-error";

import { sortTags } from "./select-tag.utilities";
import { IProps } from "./select-tag.types";
import "./select-tag.scss";

/**
 * Select one or more tags for filtering. The tags are cached for a long time as they should change rarely.
 * @param props.name Field name.
 * @returns          Tag filter.
 */
const SelectTag: FunctionComponent<IProps> = ({ name }) => {
    const { data, isFetching } = useQuery(Route.Tags, () => getAllPaginatedItems<ITag>(Route.Tags).then(sortTags), {
        cacheTime: Infinity,
        onError: (serverError: AxiosError) => toast.error(getServerError(serverError)),
        staleTime: Infinity,
    });

    return (
        <SelectField
            isLoading={isFetching}
            options={data || []}
            name={name}
            setValue={({ tag }) => tag}
            getOptionLabel={({ tag }) => tag}
            isClearable={false}
            isMulti={true}
            noResultMessage="No tags found"
            placeholder="Select tag&hellip;"
            description="tags"
        />
    );
};

export default memo(SelectTag);
