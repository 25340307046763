import Mixpanel from "mixpanel-browser";

const MIXPANEL_HOST = "https://api-eu.mixpanel.com";

// Events might occur before initialisation that need to be sent later.
let isInitialised = false;

// Backlog of tracking events happening before initialisation.
let trackingEvents: [string, object?, boolean?][] = [];

/**
 * Send a tracking event to Mixpanel.
 * @param trackingEventName Name of the event.
 * @param data              Optional event data.
 */
export const trackUser = (trackingEventName: string, data?: object, prefix?: boolean) => {
    if (isInitialised) {
        Mixpanel.track(trackingEventName, data ? convertData(data, prefix ? trackingEventName : undefined) : undefined);
    } else {
        trackingEvents.push([trackingEventName, data, prefix]);
    }
};

/**
 * Convert keys to title case and optionally add a prefix to each.They are more readable like that in Mixpanel and
 * follow the convention of the default Mixpanel properties.
 * @param data   Original data.
 * @param prefix Optional prefix for each key.
 */
const convertData = (data: object, prefix?: string) => {
    return Object.fromEntries(
        Object.entries(data).map(([key, value]) => {
            const convertedCase = convertCase(key);
            const convertedKey = typeof prefix === "string" ? `${prefix}: ${convertedCase}` : convertedCase;
            return [convertedKey, value instanceof Date ? value.toISOString() : value];
        })
    );
};

/**
 * Convert camel case key to title case. They are more readable like that in Mixpanel and follow the convention of the
 * default Mixpanel properties.
 * @param value Camel case key.
 * @returns     Title case key.
 */
const convertCase = (value: string) => {
    return value.replace(/([A-Z])/g, (match) => " " + match).replace(/^./, (match) => match.toUpperCase());
};

/**
 * Initialise Mixpanel and send some basic properties.
 * @param userId       Authenticated user's ID.
 * @param name         User's full name.
 * @param emailAddress Authenticated user's email address.
 */
export const initialiseTracking = (userId: string, name: string, emailAddress: string) => {
    Mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY!, { api_host: MIXPANEL_HOST, loaded: onTrackingInitialised }, "");
    Mixpanel.identify(userId);
    Mixpanel.people.set({ $email: emailAddress, $name: name });
    Mixpanel.register({ Version: process.env.REACT_APP_VERSION });
};

/**
 * Mark that Mixpanel has been initialised.
 */
export const onTrackingInitialised = () => {
    isInitialised = true;
    trackingEvents.forEach((trackingEvent) => trackUser.apply(null, trackingEvent));
    trackingEvents = [];
};

/**
 * Get the initialisation state.
 * @returns Initialisation state boolean.
 */
export const getIsInitialised = () => {
    return isInitialised;
};

/**
 * Reset tracking. This is for testing only.
 */
export const reset = () => {
    isInitialised = false;
    trackingEvents = [];
};
