import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { UseQueryOptions, useQuery } from "react-query";

import { axios } from "utilities/axios";
import { getServerError } from "utilities/get-server-error";
import { ITimeZonesGetQueryParameters, ITimeZonesGetResponse, Route } from "@edgetier/watchtower-types";

// If the request fails default back to UTC.
export const DEFAULT_TIME_ZONE = "UTC";

/**
 * Get the approximate time zone of the user.
 * @param url Time zones URL.
 */
const getTimeZone = async (url: string) => {
    const params: ITimeZonesGetQueryParameters = { limit: 1, offsetMinutes: -new Date().getTimezoneOffset() };
    const response = await axios.get<ITimeZonesGetResponse>(url, { params });
    if (response.data.items.length === 0) {
        toast.error("Could not find your time zone. Dates and times may be not as you expect.");
        return DEFAULT_TIME_ZONE;
    }
    return response.data.items[0].timeZone;
};

/**
 * Get the approximate time zone of the user.
 * @param configuration React Query options.
 * @returns             React Query result.
 */
export const useTimeZone = (configuration: UseQueryOptions<string, AxiosError<void>> = {}) => {
    return useQuery(Route.TimeZones, () => getTimeZone(Route.TimeZones), {
        onError: (serverError: AxiosError<any>) => {
            toast.error(getServerError(serverError));
        },
        cacheTime: Infinity,
        staleTime: Infinity,
        ...configuration,
    });
};
