import Axios from "axios";
import Qs from "qs";
import { Route, ITokensPostResponse, ITokensPostRequest } from "@edgetier/watchtower-types";
import { getRedirectUri } from "utilities/get-redirect-uri";
import { BASE_URL } from "utilities/axios";
import { toast } from "react-hot-toast";

export const LOCAL_ERROR = "Authentication failed unexpectedly.";

export const authenticate = async () => {
    const parameters = Qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    if (parameters.error || typeof parameters.code !== "string" || typeof parameters.state !== "string") {
        toast.error(LOCAL_ERROR);
        throw new Error();
    }

    const authenticationState = localStorage.getItem("authenticationState");
    const codeVerifier = localStorage.getItem("authenticationCodeVerifier");

    if (parameters.state !== authenticationState || typeof codeVerifier !== "string") {
        toast.error(LOCAL_ERROR);
        throw new Error();
    }

    const data: ITokensPostRequest = { code: parameters.code, codeVerifier, redirectUri: getRedirectUri() };
    const requestOptions = { baseURL: BASE_URL };
    const response = await Axios.post<ITokensPostResponse>(Route.Tokens, data, requestOptions);
    return response.data;
};
