import { FunctionComponent, memo } from "react";
import { ClientNavigation, NavigationLink } from "@edgetier/client-components";
import { faBellOn, faBinoculars } from "@fortawesome/pro-solid-svg-icons";

import logo from "images/icon-colour-light-background.svg";
import Page from "constants/page";
import SignOut from "./sign-out";

import { IProps } from "./navigation.types";
import "./navigation.scss";

/**
 * Sidebar with links to other pages.
 */
const Navigation: FunctionComponent<IProps> = () => {
    return (
        <ClientNavigation logo={logo}>
            <NavigationLink label="Anomalies" icon={faBellOn} page={Page.Anomalies} />
            <NavigationLink label="Explore" icon={faBinoculars} page={Page.Explore} />
            <SignOut />
        </ClientNavigation>
    );
};

export default memo(Navigation);
