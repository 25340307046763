import React, { useMemo } from "react";

import SelectField from "../select-field";
import formatEnumOptions from "../utilities/format-enum-options";

import { IEnumOption, IProps } from "./select-enum.types";

/**
 * Display a select input containing any enum values.
 * @param props.excludeOptions Optional list of items to exclude.
 * @param props.name           Form field name.
 * @param props.options        An enum.
 * @param props.otherProps     Props to pass on to React Select.
 * @returns                    Select component.
 */
const SelectEnum = ({ excludeOptions = [], name, options, ...otherProps }: IProps) => {
    const formattedOptions = useMemo(() => formatEnumOptions(options, excludeOptions), [options, excludeOptions]);
    return (
        <SelectField<IEnumOption, number>
            getOptionLabel={({ label }) => label}
            getOptionValue={({ value }) => String(value)}
            isClearable
            name={name}
            options={formattedOptions}
            placeholder="Select&hellip;"
            setValue={({ value }) => value}
            {...otherProps}
        />
    );
};

export default SelectEnum;
