import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { UseQueryOptions, useQuery } from "react-query";

import { axios } from "utilities/axios";
import { IModel, IModelGetResponse, Route } from "@edgetier/watchtower-types";
import { getServerError } from "utilities/get-server-error";

/**
 * Get the model for an anomaly.
 * @param url Start URL to get notes.
 */
const getModel = async (url: string) => {
    const response = await axios.get<IModelGetResponse>(url);
    return response.data;
};

/**
 * Get a model.
 * @param queryId Notes belong to queries. Get the notes for this one.
 * @returns       React Query result.
 */
export const useModel = (modelId: string, configuration: UseQueryOptions<IModel, AxiosError<void>> = {}) => {
    const url = Route.Model.replace(":modelId", modelId);
    return useQuery(url, () => getModel(url), {
        onError: (serverError: AxiosError<any>) => {
            toast.error(getServerError(serverError));
        },
        ...configuration,
    });
};
