import ReactToggle from "react-toggle";
import { FunctionComponent, memo } from "react";
import { Field, FieldProps } from "formik";

import { IProps } from "./proportion-toggle.types";
import "./proportion-toggle.scss";

/**
 * Toggle between an absolute or percent vertical axis.
 * @param props.name Field name.
 * @returns          Toggle control.
 */
const ProportionToggle: FunctionComponent<IProps> = ({ name }) => {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => (
                <div className="proportion-toggle">
                    <input
                        id={field.name}
                        name={field.name}
                        onChange={(changeEvent) => form.setFieldValue(field.name, changeEvent.target.checked)}
                        type="checkbox"
                    />
                    <label htmlFor={field.name}>
                        Absolute
                        <ReactToggle
                            checked={field.value}
                            onChange={(changeEvent) => form.setFieldValue(field.name, changeEvent.target.checked)}
                        />
                        Percent
                    </label>
                </div>
            )}
        </Field>
    );
};

export default memo(ProportionToggle);
